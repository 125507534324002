import Client from 'config/axios';

export interface ChallengeData {
  name: string;
  content: string;
  photo: any;
  adminId: number;
  companyId: number;
  startDate: string;
  finishDate: string;
  facebook: boolean;
  twitter: boolean;
  linkedin: boolean;
  instagram: boolean;
  tiktok: boolean;
  snapchat: boolean;
  youtube: boolean;
  threads: boolean;
  others: boolean;
  groupsIds: Array<number>;
  groups: Array<any>;
  ambassadorsIds: Array<number>;
  status: string;
  format: string;
  multimedia: any;
}

export const createChallenge = async (
  token: string,
  challenge: ChallengeData
) => {
  const body = {
    name: challenge.name,
    content: challenge.content,
    photo: challenge.photo,
    facebook: challenge.facebook,
    twitter: challenge.twitter,
    linkedin: challenge.linkedin,
    instagram: challenge.instagram,
    tiktok: challenge.tiktok,
    snapchat: challenge.snapchat,
    youtube: challenge.youtube,
    threads: challenge.threads,
    others: challenge.others,
    companyId: challenge.companyId,
    adminId: challenge.adminId,
    groupsIds: challenge.groupsIds,
    ambassadorsIds: challenge.ambassadorsIds,
    startDate: challenge.startDate,
    finishDate: challenge.finishDate,
    status: challenge.status,
    format: challenge.format,
    multimedia: challenge.multimedia
  };

  try {
    const { data } = await Client.post('challenge/create', body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return { data: data };
  } catch (response) {
    const result = { response } as any;
    return { errors: result.data };
  }
};
