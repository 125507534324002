import React, { Fragment, SyntheticEvent, useState } from 'react';
import {
  PositionColumn,
  Column,
  UserNameHeader,
  Row,
  Table,
  Th,
  SocialInfoStyled,
  Status
} from './StyledResumenTable';
import { RestorePost } from 'services/rest/post';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import StatusIcon from 'components/icons/StatusIcon';
import EmployeeAvatar from 'images/avatars/employee.png';
import iconEyes from 'images/icons/eyes.svg';
import iconNoAction from 'images/icons/no_actions.svg';
import iconStar from 'images/icons/star.svg';
import eyeStrikethroughRed from 'images/icons/eyes_strikethrough.red.svg';
import programmingPost from 'images/icons/clock-programming.svg';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import Swal from 'sweetalert2';

export type Role = 'employee' | 'admin';

interface Employee {
  employeeId: number;
  position: number;
  points: number;
  photo: string;
  name: string;
  lastName: string;
  participations: any[];
  social_network?: {
    networks?: {
      twitter?: {
        userName: string;
      };
    };
  };
  registered?: boolean;
  deleted?: boolean;
}

export type Employees = Array<Employee>;

export interface RankingTableProps {
  currentEmployeeId?: number;
  employees: Employees;
  role: Role;
  badPracticesOnClickFunction?: any;
  includesFacebook?: boolean;
  includesTwitter?: boolean;
  includesLinkedin?: boolean;
  includesInstagram?: boolean;
  includesWhatsapp?: boolean;
  includesTiktok?: boolean;
  campaignStatus?: string;
}

const postURL = (
  socialNetwork: string,
  uid: string,
  twitterUserName?: string
) => {
  let url;
  switch (socialNetwork) {
    case 'facebook':
      url = `https://facebook.com/${uid}`;
      break;
    case 'twitter':
      url = `https://twitter.com/${twitterUserName}/status/${uid}`;
      break;
    case 'linkedin':
      url = `https://linkedin.com/feed/update/${uid}`;
      break;
    case 'instagram':
      url = uid;
      break;
    case 'tiktok':
      url = `https://tiktok.com/@tiktok/video/${uid}`;
      break;
    default:
      url = uid;
      break;
  }
  return url;
};

const ResumenTable: React.FC<RankingTableProps> = props => {
  const { t } = useTranslation(['group_page', 'admin_campaign_details']);
  const { currentUser } = useSelector(selectUser);
  const [visible, setVisible] = useState({
    warningScope: false
  });

  const {
    employees,
    currentEmployeeId,
    includesFacebook = false,
    includesTwitter = false,
    includesLinkedin = false,
    includesInstagram = false,
    includesWhatsapp = false,
    includesTiktok = false,
    campaignStatus,
    badPracticesOnClickFunction
  } = props;

  const confirmAlert = (
    token: string,
    participationID: number,
    provider: string,
    ambassadorName: string,
    ambassadorLastName: string
  ) => {
    const socialNetworkV = provider.charAt(0).toUpperCase() + provider.slice(1);

    Swal.fire({
      icon: 'warning',
      html: `<div style="text-align: center">   
          <h4><strong>${t(
            'admin_campaign_details:alert_restore_post:title-a'
          )}</strong></h4>
          <div class="instruction" style="font-size: 17px;">
              <p>${t(
                'admin_campaign_details:alert_restore_post:title-b'
              )} <strong>${socialNetworkV}</strong></p>
          </div>
          <div class="instruction" style="font-size: 17px;">
              <p>${t(
                'admin_campaign_details:alert_restore_post:title-c'
              )} <strong>${ambassadorName} ${ambassadorLastName}</strong></p>        
          </div>
          <div class="instruction" style="font-size: 17px;">
            <p>${t('admin_campaign_details:alert_restore_post:title-d')}</p>
          </div>`,
      customClass: 'show',
      confirmButtonText: t('admin_campaign_details:alert_restore_post:yes'),
      reverseButtons: true,
      showDenyButton: true,
      denyButtonColor: '#636C74',
      denyButtonText: t('admin_campaign_details:alert_restore_post:cancel'),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await RestorePost(token, participationID, provider);
          if (data.data) {
            return true;
          }
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire(
          t('admin_campaign_details:alert_restore_post:aproved_message'),
          '',
          'success'
        );
        window.location.reload();
      } else if (result.isDenied) {
        Swal.fire(
          t('admin_campaign_details:alert_restore_post:denied_message'),
          '',
          'info'
        );
      }
    });
  };

  const social_info = (
    ambassadorName: string,
    ambassadorLastName: string,
    participations: any[],
    socialNetwork: string,
    campaignStatus?: string,
    twitterUserName?: string
  ) => {
    return (
      <SocialInfoStyled className="contentPoints">
        {participations?.length > 0 ? (
          <>
            {participations[0]?.networks?.[socialNetwork] ? (
              <>
                {participations[0]?.networks[socialNetwork]?.status ===
                'deleted' ? (
                  campaignStatus === 'activated' ? (
                    <Fragment>
                      <a
                        href=""
                        onClick={e => {
                          e.preventDefault();
                          confirmAlert(
                            currentUser.token,
                            participations[0]?.id,
                            socialNetwork,
                            ambassadorName,
                            ambassadorLastName
                          );
                        }}
                      >
                        <img src={eyeStrikethroughRed} alt="deleted post" />
                      </a>
                    </Fragment>
                  ) : (
                    <img src={eyeStrikethroughRed} alt="deleted post" />
                  )
                ) : (
                  <>
                    {participations[0]?.networks[socialNetwork]?.status ===
                    'accepted' ? (
                      <img src={iconNoAction} alt="no action" />
                    ) : (
                      <>
                        {participations[0]?.networks[socialNetwork]?.status ===
                        'programmed' ? (
                          <img src={programmingPost} alt="programmed post" />
                        ) : (
                          <>
                            {socialNetwork === 'whatsapp' ? (
                              <Fragment>
                                <img src={iconEyes} alt="view post" />
                                <span>
                                  <b>
                                    {participations[0]?.networks[socialNetwork]
                                      ?.totalPoints || 0}
                                  </b>
                                  <a
                                    href=""
                                    onClick={e => {
                                      e.preventDefault();
                                      confirmAlert(
                                        currentUser.token,
                                        participations[0]?.id,
                                        socialNetwork,
                                        ambassadorName,
                                        ambassadorLastName
                                      );
                                    }}
                                  >
                                    <img
                                      className="points"
                                      src={iconStar}
                                      alt="points"
                                    />
                                  </a>
                                </span>
                              </Fragment>
                            ) : socialNetwork === 'instagram' ? (
                              <Fragment>
                                <a
                                  href={postURL(
                                    socialNetwork,
                                    participations[0]?.networks[socialNetwork]
                                      ?.uid,
                                    twitterUserName
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src={iconEyes} alt="view post" />
                                </a>
                                <span>
                                  <b>
                                    {participations[0]?.networks[socialNetwork]
                                      ?.totalPoints || 0}
                                  </b>
                                  <a
                                    href=""
                                    onClick={e => {
                                      e.preventDefault();
                                      confirmAlert(
                                        currentUser.token,
                                        participations[0]?.id,
                                        socialNetwork,
                                        ambassadorName,
                                        ambassadorLastName
                                      );
                                    }}
                                  >
                                    <img
                                      className="points"
                                      src={iconStar}
                                      alt="points"
                                    />
                                  </a>
                                </span>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <a
                                  href={postURL(
                                    socialNetwork,
                                    participations[0]?.networks[socialNetwork]
                                      ?.uid,
                                    twitterUserName
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src={iconEyes} alt="view post" />
                                </a>
                                <span>
                                  <b>
                                    {participations[0]?.networks[socialNetwork]
                                      ?.totalPoints || 0}
                                  </b>
                                  <img
                                    className="points"
                                    src={iconStar}
                                    alt="points"
                                  />
                                </span>
                              </Fragment>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <img src={iconNoAction} alt="no action" />
            )}
          </>
        ) : (
          <img src={iconNoAction} alt="no action" />
        )}
      </SocialInfoStyled>
    );
  };

  const total_points = (total_points: number) => {
    return (
      <SocialInfoStyled>
        <div className="totalPoints">
          <b>{total_points || 0}</b>
          <img className="points" src={iconStar} alt="points" />
        </div>
      </SocialInfoStyled>
    );
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const changeTooltip = (tooltipType: string, state: boolean) => {
    setVisible(prev => ({
      ...prev,
      [tooltipType]: state
    }));
  };

  return (
    <Table className="notranslate">
      <thead>
        <Row>
          <Th />
          <UserNameHeader>{t('group_page:name')}</UserNameHeader>
          {includesFacebook && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'facebook'} size="medium" />
            </UserNameHeader>
          )}
          {includesTwitter && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'twitter'} size="medium" />
            </UserNameHeader>
          )}
          {includesLinkedin && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'linkedin'} size="medium" />
            </UserNameHeader>
          )}
          {includesInstagram && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'instagram'} size="medium" />
            </UserNameHeader>
          )}
          {includesWhatsapp && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'whatsapp'} size="medium" />
            </UserNameHeader>
          )}
          {includesTiktok && (
            <UserNameHeader className="center">
              <SocialMediaIcon name={'tiktok'} size="medium" />
            </UserNameHeader>
          )}
          <UserNameHeader className="center">
            {t('campaigns_page:total_points')}
          </UserNameHeader>
        </Row>
      </thead>
      <tbody>
        {employees.map((employee, index) => {
          const isCurrent = currentEmployeeId === employee.employeeId;
          const showBadPractices: boolean =
            props.role === 'admin' &&
            employee.participations[0]?.networks?.facebook
              ?.suspiciousBadPractice &&
            employee.participations[0]?.networks?.facebook
              ?.badPracticeDiscarded === false;

          return (
            <Row key={index} activeRow={isCurrent}>
              <PositionColumn
                collapsed
                style={{ columns: showBadPractices ? 2 : 1 }}
              >
                <div className="id">
                  <img
                    src={employee.photo || EmployeeAvatar}
                    className="user-photo"
                    alt="User"
                    onError={addDefaultPicture}
                  />
                </div>
                {employee.registered === true && (
                  <Status>
                    <StatusIcon variant={'verified'} />
                  </Status>
                )}
                {employee.deleted === true && (
                  <Status>
                    <StatusIcon variant={'danger'} />
                  </Status>
                )}

                {showBadPractices && (
                  <div style={{ display: 'inline-flex' }}>
                    <div className="centro">
                      <StyledTooltipWrapper
                        visible={visible.warningScope}
                        onMouseOver={() => changeTooltip('warningScope', true)}
                        onMouseOut={() => changeTooltip('warningScope', false)}
                      >
                        <Icon name={'warning'} size="medium" />
                        <TooltipMessage>
                          {t('admin_campaign_details:warning_bad_practices')}
                        </TooltipMessage>
                      </StyledTooltipWrapper>
                      <div>
                        <Icon
                          onClick={() => {
                            if (badPracticesOnClickFunction) {
                              badPracticesOnClickFunction(
                                employee.participations[0].id
                              );
                            }
                          }}
                          name={'error-signal'}
                          size="medium"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </PositionColumn>
              <Column>
                {employee.name} {employee.lastName}
              </Column>
              {includesFacebook && (
                <Column className="center">
                  {social_info(
                    employee.name,
                    employee.lastName,
                    employee.participations,
                    'facebook',
                    campaignStatus
                  )}
                </Column>
              )}
              {includesTwitter && (
                <Column className="center">
                  {social_info(
                    employee.name,
                    employee.lastName,
                    employee.participations,
                    'twitter',
                    campaignStatus,
                    employee.social_network?.networks?.twitter?.userName
                  )}
                </Column>
              )}
              {includesLinkedin && (
                <Column className="center">
                  {social_info(
                    employee.name,
                    employee.lastName,
                    employee.participations,
                    'linkedin',
                    campaignStatus
                  )}
                </Column>
              )}
              {includesInstagram && (
                <Column className="center">
                  {social_info(
                    employee.name,
                    employee.lastName,
                    employee.participations,
                    'instagram',
                    campaignStatus
                  )}
                </Column>
              )}
              {includesWhatsapp && (
                <Column className="center">
                  {social_info(
                    employee.name,
                    employee.lastName,
                    employee.participations,
                    'whatsapp',
                    campaignStatus
                  )}
                </Column>
              )}
              {includesTiktok && (
                <Column className="center">
                  {social_info(
                    employee.name,
                    employee.lastName,
                    employee.participations,
                    'tiktok',
                    campaignStatus
                  )}
                </Column>
              )}
              <Column className="center">
                {total_points(employee.participations[0]?.totalPoints)}
              </Column>
            </Row>
          );
        })}
      </tbody>
    </Table>
  );
};

export default ResumenTable;
