import type React from 'react';
import { useTranslation } from 'react-i18next';
import { NavBarWarning as StyledNavBarWarning } from './StyledNavBar';

interface NavBarWarningProps {
  onClose: () => void;
  isVisible: boolean;
  daysUntilExpiration?: number | null;
}

export const NavBarWarning: React.FC<NavBarWarningProps> = ({
  onClose,
  isVisible,
  daysUntilExpiration
}) => {
  const { t } = useTranslation(['layout', 'social_network']);

  if (!isVisible) return null;

  return (
    <StyledNavBarWarning>
      <div className="icon-container">
        <span>!</span>
      </div>

      <div className="content">
        <div className="title">
          {t('layout:contract_expiration', { days: daysUntilExpiration })}
        </div>
        <div className="subtitle">{t('layout:contact_for_renewal')}</div>
      </div>

      <div className="actions">
        <a
          href="https://sge.st/AdvocatesProRenovacion"
          className="renew-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('layout:renew')}
        </a>
        <button className="close-button" onClick={onClose}>
          ✕
        </button>
      </div>
    </StyledNavBarWarning>
  );
};
