import React, { useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Instagram as LoadingState } from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { selectRouter } from 'redux/slices/Router/RouterSlice';
import { postNetworkToken } from 'services/rest/Network/Network';
import { selectUser, setUserInfo } from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/logout';
import Swal from 'sweetalert2';
import FacebookLogo from 'images/logos/logo-facebook.png';
import ScreenPhoto from 'images/avatars/FacebookSearch.png';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

interface Network {
  network: string;
}

interface ITwitterAccess {
  oauth_token: string;
  oauth_verifier: string;
}

export const getAccessTokenNetwork = (resquet: string): string => {
  const getToken = resquet.split('=');
  const toClearToken = getToken[1].split('&');
  const token = toClearToken[0];
  return token;
};

export const getAccessTokenTwitter = (resquet: string): ITwitterAccess => {
  const getToken = resquet.split('=');
  const toClearToken = getToken[1].split('&');
  const token = toClearToken[0];
  return { oauth_token: token, oauth_verifier: getToken[2] };
};

export const getAccessTokenTiktok = (resquet: string): string => {
  const getToken = resquet.split('=');
  const token = getToken[1];
  return token;
};

const CallBack = () => {
  const { t, i18n } = useTranslation(['networks_list', 'social_network']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { network } = useParams<Network>();

  const { currentUser } = useSelector(selectUser);
  const { company } = useSelector(selectCompany);
  const { router } = useSelector(selectRouter);
  const { hash, search } = useLocation();

  const getData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string,
    code: string,
    facebookProfileUrl: string
  ) => {
    const campaignId = localStorage.getItem('idCampaignConnect');

    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName,
        code: code,
        facebookProfileUrl: facebookProfileUrl
      },
      network
    )
      .then(response => {
        if (response.data) {
          getInfoUser(currentUser?.user?.id, currentUser?.token).then(
            response => {
              dispatch(setUserInfo(response.data));
              history.replace(campaignId ? `/campaigns/${campaignId}` : router);
            }
          );
        } else {
          history.replace(campaignId ? `/campaigns/${campaignId}` : router);
        }
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: t('networks_list:error_title'),
          text: t('networks_list:error_message'),
          confirmButtonColor: 'black'
        }).then(result => {
          if (result.isConfirmed) {
            handleFacebook();
          }
        });
      });
  };

  const handleFacebook = async () => {
    if (hash !== '') {
      const token = getAccessTokenNetwork(hash);
      let userName;
      do {
        const result = await Swal.fire({
          imageUrl: FacebookLogo,
          color: 'Black',
          width: 400,
          imageWidth: 70,
          imageHeight: 70,
          input: 'text',
          showCloseButton: true,
          inputPlaceholder: t('networks_list:facebook_placeholder'),
          inputLabel: '',
          html: `<div style="text-align: center">   
          <h4><strong>${t('social_network:title')}</strong></h4>
          <div class="instruction" style="font-size: 17px;">
              <p>${t('social_network:fb_step1')}</p>
          </div>
          <div class="instruction" style="font-size: 17px;">
              <p>${t('social_network:fb_step2')}</p>        
              <img src="${ScreenPhoto}" alt="Señalando el URL en el navegador" class="image-placeholder">
          </div>
          <div class="instruction" style="font-size: 17px;">
            <p>${t('social_network:fb_step3')}</strong></p>
          </div>`,
          confirmButtonColor: 'black',
          confirmButtonText: t('networks_list:complete'),
          inputValidator: value => {
            if (!value || !value.includes('https://www.facebook.com/')) {
              return t('networks_list:facebook_error');
            }
            return null;
          }
        });

        userName = result.value;

        if (result.dismiss === Swal.DismissReason.close) {
          const campaignId = localStorage.getItem('idCampaignConnect');
          history.replace(
            campaignId ? `/campaigns/${campaignId}` : '/dashboard'
          );
          return;
        }
      } while (!userName || !userName.includes('https://www.facebook.com/'));

      getData(network, token, '', '', '', '', userName);
    }
  };

  useEffect(() => {}, [i18n.isInitialized]);

  useEffect(() => {
    switch (network) {
      case 'facebook':
        if (company.id === 143) {
          const token = getAccessTokenNetwork(hash);
          getData(network, token, '', '', '', '', '');
        } else {
          handleFacebook();
        }
        break;
      case 'linkedin':
        if (search !== '') {
          const code = getAccessTokenNetwork(search);
          getData(network, '', '', '', '', code, '');
        }
        break;
      case 'twitter':
        if (search !== '') {
          const code = getAccessTokenTwitter(search);
          getData(
            network,
            code.oauth_token,
            '',
            code.oauth_verifier,
            '',
            '',
            ''
          );
        }
        break;
      case 'tiktok':
        if (search !== '') {
          const code = getAccessTokenTiktok(search);
          getData(network, '', '', '', '', code, '');
        }
        break;
    }
  }, [network, hash, search, i18n.isInitialized]);

  return <LoadingState height="230px" width="100%" />;
};

export default CallBack;
