import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import {
  DivIcon,
  GridWrapper,
  MarginDiv,
  MarginStatus,
  MarginText,
  SocialNetworkTags
} from '../styled';
import iconTrash from 'images/icons/trash.png';
import iconPaused from 'images/icons/paused.svg';
import iconActivated from 'images/icons/activated.svg';
import iconEye from 'images/icons/eyes.svg';
import Tag from 'components/Tag/Tag';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { deleteCampaign } from 'services/rest/Campaign/deleteCampaign';
import { updateStatusCampaign } from 'services/rest/Campaign/updateStatusCampaign';

export interface CampaignsAdminCardProp {
  id: number;
  url: string;
  startDate: string;
  finishDate: string;
  description: string;
  active: string;
  publicacion: string;
  iconEdit: string;
  status?: string;
  includesFacebook?: boolean;
  includesTwitter?: boolean;
  includesLinkedin?: boolean;
  includesInstagram?: boolean;
  includesWhatsapp?: boolean;
  includesTiktok?: boolean;
  includesStatus?: boolean;
}

const CampaignsAdminCard: React.FC<CampaignsAdminCardProp> = props => {
  const {
    url,
    startDate,
    finishDate,
    description,
    active,
    publicacion,
    iconEdit,
    id,
    status,
    includesFacebook = false,
    includesTwitter = false,
    includesLinkedin = false,
    includesInstagram = false,
    includesWhatsapp = false,
    includesTiktok = false,
    includesStatus = false
  } = props;
  const { currentUser } = useSelector(selectUser);
  const { t } = useTranslation(['campaign_card']);

  const timeLine = (startDate: any, finishDate: any) => {
    startDate = moment(startDate);
    finishDate = moment(finishDate);
    const startMonth = startDate.format('MMM');
    const startDay = startDate.format('D');
    const finishMonth = finishDate.format('MMM');
    const finishDay = finishDate.format('D');
    return `${startMonth} ${startDay} - ${finishMonth} ${finishDay}`;
  };

  const statusText = (status: any) => {
    let statusNew;
    if (status === 'activated') {
      statusNew = t('campaign_card:active_campaign');
    }
    if (status === 'completed') {
      statusNew = t('campaign_card:completed_campaign');
    }
    if (status === 'pending') {
      statusNew = t('campaign_card:pending_campaign');
    }
    if (status === 'paused') {
      statusNew = t('campaign_card:paused_campaign');
    }
    if (status === 'draft') {
      statusNew = t('campaign_card:draft_campaign');
    }
    return statusNew;
  };

  const onSubmit = async (token: any, campaignId: any, action: any) => {
    if (action === 'deleted') {
      const { data } = await deleteCampaign(token, campaignId);
      if (data.data) {
        window.location.reload();
      }
    } else {
      const { data } = await updateStatusCampaign(token, campaignId, action);
      if (data.data) {
        window.location.reload();
      }
    }
  };

  const pausedAlert = (campaignId: number) => {
    Swal.fire({
      text: t('campaign_card:confirmation_paused_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(currentUser?.token, campaignId, 'paused');
      }
    });
  };

  const activatedAlert = (campaignId: number) => {
    Swal.fire({
      text: t('campaign_card:confirmation_actived_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(currentUser?.token, campaignId, 'activated');
      }
    });
  };

  const confirmAlert = (campaignId: number) => {
    Swal.fire({
      text: t('campaign_card:confirmation_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(currentUser?.token, campaignId, 'deleted');
      }
    });
  };

  const go = (id: number) => {
    window.open(`/campaigns/admin/detail/${id}`, '_blank', 'noreferrer');
  };
  return (
    <GridWrapper className="hide-on-mobile activated-campaign content-box content-box-horizontal flex-box-container hoverable small">
      {status === 'pending' && <Tag value="Proximamente" variant="invert" />}
      <div className="content-box-header content-img">
        {includesStatus && (
          <Link
            to={{
              pathname: `/campaigns/admin/detail/` + id,
              search: `?All`
            }}
          >
            <img src={url} alt="new" />
          </Link>
        )}
        {!includesStatus && (
          <Link to={`/campaigns/admin/detail/` + id}>
            <img src={url} alt="new" />
          </Link>
        )}
        <SocialNetworkTags>
          {includesFacebook && <Tag variant="bottom" network="facebook" />}
          {includesTwitter && <Tag variant="bottom" network="twitter" />}
          {includesLinkedin && <Tag variant="bottom" network="linkedin" />}
          {includesInstagram && <Tag variant="bottom" network="instagram" />}
          {includesWhatsapp && <Tag variant="bottom" network="whatsapp" />}
          {includesTiktok && <Tag variant="bottom" network="tiktok" />}
        </SocialNetworkTags>
      </div>
      <MarginText className="m-auto">
        {includesStatus && (
          <Link
            to={{
              pathname: `/campaigns/admin/detail/` + id,
              search: `?All`
            }}
          >
            <p className="time-line">{timeLine(startDate, finishDate)}</p>
            <div className={description.length > 50 ? 'overflow-elipsis' : ''}>
              <h2>{description}</h2>
            </div>
          </Link>
        )}
        {!includesStatus && (
          <Link to={`/campaigns/admin/detail/` + id}>
            <p className="time-line">{timeLine(startDate, finishDate)}</p>
            <div className={description.length > 50 ? 'overflow-elipsis' : ''}>
              <h2>{description}</h2>
            </div>
          </Link>
        )}
      </MarginText>
      <div className="content-box-body campaign-stats" onClick={() => go(id)}>
        {includesStatus && (
          <MarginStatus className="division-right">
            <p>{statusText(status)}</p>
            <h3>{t('campaign_card:status')}</h3>
          </MarginStatus>
        )}
        {!includesStatus && (
          <MarginDiv className="division-right">
            <p>{active}</p>
            <h3>{t('campaign_card:active_ambassadors')}</h3>
          </MarginDiv>
        )}
        <MarginDiv>
          <p>{publicacion}</p>
          <h3>{t('campaign_card:total_publications')}</h3>
        </MarginDiv>
      </div>
      <DivIcon>
        <div>
          {includesStatus && (
            <Link
              to={{
                pathname: `/campaigns/admin/${id}/edit`,
                search: `?All`
              }}
            >
              <img
                alt="statusIcon"
                src={
                  status === 'pending' ||
                  status === 'paused' ||
                  status === 'draft'
                    ? iconEdit
                    : iconEye
                }
              />
            </Link>
          )}
          {!includesStatus && (
            <Link to={`/campaigns/admin/` + id + `/edit`}>
              <img
                alt="statusIcon"
                src={
                  status === 'pending' ||
                  status === 'paused' ||
                  status === 'draft'
                    ? iconEdit
                    : iconEye
                }
              />
            </Link>
          )}
        </div>

        {status === 'activated' && (
          <div>
            <button onClick={() => pausedAlert(id)}>
              <img alt="statusIcon" src={iconPaused} />
            </button>
          </div>
        )}

        {status === 'paused' && (
          <div>
            <button onClick={() => activatedAlert(id)}>
              <img alt="statusIcon" src={iconActivated} />
            </button>
          </div>
        )}

        {(status === 'pending' ||
          status === 'paused' ||
          status === 'draft') && (
          <div>
            <button onClick={() => confirmAlert(id)}>
              <img alt="statusIcon" src={iconTrash} />
            </button>
          </div>
        )}
      </DivIcon>
    </GridWrapper>
  );
};

export default CampaignsAdminCard;
