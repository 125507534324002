import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BodyWrapper from './StyledLayout';
import { AnimatedNavBar } from 'components/NavBar';
import {
  selectUser,
  setUserInfo,
  setUserInfoLoading
} from 'redux/slices/userSlice';
import useAdmin from 'hooks/useIsAdmin.hook';
import { CountPendingBonus } from 'helpers/bonusHelper';
import { getInfoUser } from 'services/rest/User/getInfoUser';
import { ErrorAlert } from 'components/Alert';
import AmbassadorAvatar from 'images/avatars/employee.png';
import {
  selectInvalidToken,
  setInvalidTokenCurrent
} from 'redux/slices/InvalidToken/InvalidTokenSlice';
import { postNetworkToken } from 'services/rest/Network/Network';
import { urlFacebook, urlLinkedin } from 'constants/setup.constants';
import { TwitterToken } from 'services/rest/Network/twitterToken';
import Swal from 'sweetalert2';
import { Colors } from 'styles/Constants';
import InstagramLogo from 'images/logos/logo-instragram.png';
import { useFetch } from 'hooks/useFetch.hook';
import { userLogout } from 'hooks/useLogout.hook';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import { TiktokToken } from 'services/rest/Network/getAuthToken';

interface LayoutProps {
  pathname: string;
}

const Layout: React.FC<LayoutProps> = props => {
  const { pathname, children } = props;
  const { t } = useTranslation(['layout', 'social_network', 'profile']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector(selectUser);
  const { invalidToken } = useSelector(selectInvalidToken);
  const { company } = useSelector(selectCompany);
  const { data, loading } = useFetch(
    getInfoUser(currentUser?.user?.id, currentUser?.token, currentUser?.role)
  );
  const userData = data as any;

  const licenseExpirationDate = userData?.company?.licenseDate;
  const [isModalShown, setIsModalShown] = useState(false);

  useEffect(() => {
    if (!loading && !isModalShown) {
      const today = new Date();
      const licenseRawDate = userData?.company?.licenseDate;
      const licenseExpirationDate = licenseRawDate
        ? new Date(licenseRawDate)
        : null;

      const licenseDate = licenseExpirationDate?.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      if (
        licenseExpirationDate &&
        !isNaN(licenseExpirationDate.getTime()) &&
        licenseExpirationDate < today
      ) {
        Swal.fire({
          customClass: {
            popup: 'my-popup-class',
            title: 'my-title-class',
            htmlContainer: 'my-html-expired',
            confirmButton: 'my-confirm-class',
            cancelButton: 'my-cancel-class'
          },
          title: 'Licencia expirada',
          html: `Tu contratación de AdvocatesPro finalizó el día <br><strong>${licenseDate}</strong>.<br><br>
               Si quieres poder seguir realizando campañas para aprovechar el poder de tus colaboradores, 
               te invitamos a ponerte en contacto con nosotros vía WhatsApp.`,
          icon: 'warning',
          iconColor: '#F27474',
          showCancelButton: true,
          confirmButtonText: 'Renovar',
          cancelButtonText: 'Cerrar sesión',
          confirmButtonColor: Colors.primary,
          cancelButtonColor: Colors.error,
          showCloseButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(result => {
          if (result.isConfirmed) {
            window.location.href = 'https://sge.st/AdvocatesProRenovacion';
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            userLogout(currentUser, userData, dispatch, history);
          }
        });
        setIsModalShown(true);
      }
    }
  }, [loading, isModalShown, licenseExpirationDate]);

  const postData = async (
    network: string,
    token: string,
    secret: string,
    verifier: string,
    screenName: string
  ) => {
    await postNetworkToken(
      currentUser?.token,
      {
        token: token,
        followers: 1,
        photo: '',
        uid: 1,
        secret: secret,
        verifier: verifier,
        screenName: screenName
      },
      network
    )
      .then(response => {
        dispatch(setUserInfoLoading(true));
        if (response.data) {
          getInfoUser(
            currentUser?.user?.id,
            currentUser?.token,
            'ambassador'
          ).then(response => {
            dispatch(setUserInfo(response.data));
            dispatch(setUserInfoLoading(false));
          });
        }
      })
      .catch(error => {
        dispatch(setUserInfoLoading(false));
      });
  };

  const conect = (conect: string) => {
    switch (conect) {
      case 'facebook':
        window.location.href = urlFacebook(
          company?.facebookCredentials.apiKey,
          conect
        );
        break;
      case 'twitter':
        TwitterToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));
        break;
      case 'instagram':
        Swal.fire({
          color: Colors.secondaryDarkBlue,
          width: 400,
          imageUrl: InstagramLogo,
          imageWidth: 200,
          imageHeight: 100,
          input: 'text',
          showCloseButton: true,
          inputLabel: t('social_network:UserName'),
          confirmButtonColor: Colors.secondaryPurple
        }).then(result => {
          if (result.value != '') {
            if (result.value != undefined) {
              postData(conect, 'code', '', '', result.value);
            }
          }
        });
        break;
      case 'linkedin':
        window.location.href = urlLinkedin('78xnlxviahs2ca', conect);
        break;
      case 'tiktok':
        TiktokToken(currentUser?.token)
          .then(response => (window.location.href = response.data.data))
          .catch(error => console.log(error));
        break;
    }
  };

  useEffect(() => {
    dispatch(setInvalidTokenCurrent(''));
  }, []);

  const badgetBonus = useAdmin() ? CountPendingBonus() > 0 : false;

  let links;

  const actions = [
    {
      text: t('layout:settings'),
      onClick: async () => {
        history.replace({ pathname: '/settings' });
      }
    },
    {
      text: t('layout:edit_company'),
      onClick: async () => {
        history.replace({ pathname: '/company-settings' });
      }
    },
    {
      text: t('layout:sign_out'),
      onClick: () => userLogout(currentUser, userData, dispatch, history)
    }
  ];

  const ambassadors = [
    {
      to: '/dashboard',
      icon: 'dashboard',
      text: t('layout:dashboard')
    },
    {
      to: '/creators',
      icon: 'challenges',
      text: t('layout:creators')
    },
    {
      to: '/suggested-posts',
      icon: 'suggested-posts',
      text: t('layout:suggested_posts')
    },
    {
      to: '/faq',
      icon: 'question',
      text: t('layout:faq')
    },
    {
      to: '/legal',
      icon: 'terms',
      text: t('layout:terms'),
      target: '_blank'
    }
  ];

  const admin = [
    {
      to: '/dashboard',
      icon: 'dashboard',
      text: t('layout:dashboard')
    },
    {
      to: '/admin/campaigns',
      icon: 'campaigns',
      text: t('layout:campaigns')
    },
    {
      to: '/creators',
      icon: 'challenges',
      text: t('layout:creators'),
      target: '',
      badget: badgetBonus
    },
    {
      to: '/suggested-posts',
      icon: 'suggested-posts',
      text: t('layout:suggested_posts')
    },
    {
      to: '/admin/group',
      icon: 'group',
      text: t('layout:groups')
    },
    {
      to: '/admin/award',
      icon: 'awards',
      text: t('layout:awards')
    },
    {
      to: '/admin/reports',
      icon: 'reports',
      text: t('layout:reports')
    }
  ];

  const superAdmin = [
    {
      to: '/companies',
      icon: 'companies',
      text: t('layout:companies')
    },
    {
      to: '/administrators',
      icon: 'administrators',
      text: t('layout:administrators')
    }
  ];

  switch (currentUser.role) {
    case 'ambassador':
      links =
        userData?.allowBonus && company?.company_permission?.allowShowBonus
          ? ambassadors
          : ambassadors.filter((option: any) => option.to !== '/creators');
      links =
        !userData?.allowSuggestedPost ||
        !company?.company_permission?.allowShowSuggestedPosts
          ? links.filter((option: any) => option.to !== '/suggested-posts')
          : links;
      actions.splice(1, 1);
      break;
    case 'admin':
      links = !company?.company_permission?.allowShowSuggestedPosts
        ? admin.filter((option: any) => option.to !== '/suggested-posts')
        : admin;
      break;
    case 'super':
      links = superAdmin;
      break;
    default:
      links = ambassadors;
      actions.splice(1, 1);
      break;
  }

  const navigationLinks = links.map(link => ({
    ...link,
    active: link.to === pathname
  }));

  let fullName: string;
  if (useAdmin()) {
    fullName = `${userData?.name}`;
  } else if (currentUser.role === 'super') {
    fullName = `${userData?.email}`;
  } else {
    fullName = `${userData?.name} ${userData?.lastName}`;
  }

  const userPhoto = useAdmin() ? company?.logo : userData?.photo;

  const isDashboard = pathname === '/dashboard';
  const isCampaign = pathname.includes('campaign');

  return (
    <>
      {invalidToken !== '' && (isDashboard || isCampaign) && (
        <ErrorAlert
          text={`${t('social_network:token_permissions', {
            invalidToken
          })}, ${t('social_network:update_permissions')}`}
          textOnClick={t('social_network:here')}
          onClick={() => conect(invalidToken)}
          allowClose={true}
          onClose={() => setInvalidTokenCurrent(null)}
        />
      )}

      <AnimatedNavBar
        blueNavbar={isDashboard}
        navigationShadow={true}
        customScroll={30}
        customWidth={640}
        actions={actions}
        links={navigationLinks}
        userName={fullName}
        userPhoto={userPhoto || AmbassadorAvatar}
        loading={loading}
        licenseExpirationDate={licenseExpirationDate}
      />
      <BodyWrapper showHeader={isDashboard} showLinks={true}>
        {children}
      </BodyWrapper>
    </>
  );
};

export default Layout;
