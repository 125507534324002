import React from 'react';
import { useState } from 'react';
import {
  NavBarWrapper,
  NavBarHeader,
  NavBarLogo,
  NavBarLinks
} from './StyledNavBar';

import NavigationLinks, {
  NavigationLinkProps,
  NavigationLinksProps
} from './NavigationLinks';
import MobileLinks from './MobileLinks';
import { NavBarWarning } from './NavBarWarning';
import Dropdown, { DropdownItemProps } from 'components/dropdowns/Dropdown';

import AdvocatesLogo from 'images/logos/advocates_logo.svg';
import AdvocatesLigthLogo from 'images/logos/advocates_light_logo.svg';

export interface NavBarProps extends NavigationLinksProps {
  id?: string;
  className?: string;
  userPhoto: string;
  userName: string;
  actions: Array<DropdownItemProps>;
  loading?: boolean;
  licenseExpirationDate?: string;
}

const NavBar: React.FC<NavBarProps> = props => {
  const {
    id,
    className,
    userPhoto,
    userName,
    actions,
    links,
    loading,
    licenseExpirationDate
  } = props;

  const mobileLinks: Array<NavigationLinkProps> = actions.map(action => ({
    text: action.text,
    onClick: action.onClick
  }));

  const [isWarningVisible, setIsWarningVisible] = useState(true);

  const getDaysUntilExpiration = (expirationDate: string): number | null => {
    if (!expirationDate) return null;

    const today = new Date();
    const expiration = new Date(expirationDate);
    const diffTime = expiration.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays < 0 ? 0 : diffDays;
  };

  const daysUntilExpiration = licenseExpirationDate
    ? getDaysUntilExpiration(licenseExpirationDate)
    : null;

  const shouldShowWarning =
    daysUntilExpiration !== null && daysUntilExpiration <= 60;

  const handleCloseWarning = () => {
    setIsWarningVisible(false);
  };

  return (
    <NavBarWrapper id={id} className={className}>
      <NavBarHeader>
        <NavBarLogo>
          <a href="/">
            <img className="dark-logo" src={AdvocatesLogo} alt="Advocates" />
            <img
              className="light-logo"
              src={AdvocatesLigthLogo}
              alt="Advocates"
            />
          </a>
        </NavBarLogo>

        {shouldShowWarning && isWarningVisible && (
          <NavBarWarning
            onClose={handleCloseWarning}
            isVisible={isWarningVisible}
            daysUntilExpiration={daysUntilExpiration}
          />
        )}
        <NavBarLinks>
          {!loading && (
            <>
              <span>{userName}</span>
              <img className="user-photo" src={userPhoto} alt="User" />
              <Dropdown className="desktop-links" items={actions} />
            </>
          )}
        </NavBarLinks>
      </NavBarHeader>
      <MobileLinks
        className="mobile-navigation"
        links={links.concat(mobileLinks)}
      />
      <NavigationLinks
        id="desktop_navigation"
        className="desktop-navigation"
        links={links}
      />
    </NavBarWrapper>
  );
};

export default NavBar;
