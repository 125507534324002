import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Facebook as LoadingState } from 'react-content-loader';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers';
import { selectUser } from 'redux/slices/userSlice';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import {
  selectGroupAmbassador,
  setData,
  setLoadingGroupAmbassador
} from 'redux/slices/GroupAmbassador/GroupAmbassadorSlice';
import { getGroupAmbassador } from 'services/rest/GetGroupAmbassador/GetGroupAmbassador';
import { RequiredString } from 'helpers/yupHelper';
import { sortGroups } from 'helpers/groupsHelper';
import {
  awsUploadFileHelper,
  setFileNameHelper
} from 'helpers/awsCampaignFileHelper';
import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { getUrlS3 } from 'constants/setup.constants';
import { ErrorAlert } from 'components/Alert';
import { SuccessAlert } from 'components/Alert';
import EditChallengeForm from './EditChallengeForm';
import { getChallengeById } from 'services/rest/Challenge/getChallengeById';
import { updateChallenge } from 'services/rest/Challenge/updateChallenge';
import { awsUploadVideoHelper } from 'helpers/awsVideoHelper';

interface challengeParams {
  challengeId: string;
  action: string;
}

const EditChallengePage: React.FC = () => {
  const { t } = useTranslation(['challenge', 'validations']);
  const dispatch = useDispatch();
  const userSchema = yup.object().shape({
    name: RequiredString().max(
      150,
      t('validations:length_error', { max: 150 })
    ),
    content: RequiredString().max(
      3000,
      t('validations:length_error', { max: 3000 })
    )
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });

  useEffect(() => {
    dispatch(setLoadingGroupAmbassador(true));
    getGroup('');
    getChallengeData();
  }, []);

  const [challenge, setChallenge] = useState<any>();
  const [backendError, setBackendError] = useState<string | null>(null);
  const [errorSocialNetwork, setErrorSocialNetwork] = useState<string>('');
  const [errorFormat, setErrorFormat] = useState<string>('');
  const [startDate, setStartDate] = useState<any>('');
  const [finishDate, setFinishDate] = useState<any>('');
  const [hourt, setHourt] = useState<any>('00');
  const [minutes, setMinutes] = useState<any>('00');

  const [image, setImage] = useState<Array<any>>([]);
  const [checkMultimedia, setCheckMultimedia] = useState<boolean>(false);
  const [multimediaContent, setMultimediaContent] = useState<Array<any>>([]);
  const [uploadedMultimedia, setUploadedMultimedia] = useState<boolean | null>(
    null
  );
  const [group, setGroup] = useState<Array<number>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<number>>([]);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { challengeId, action } = useParams<challengeParams>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [editImage, setEditImage] = useState<boolean>(false);
  const [editMultimedia, setEditMultimedia] = useState<boolean>(false);
  const { currentUser } = useSelector(selectUser);

  const { groupsAmbassador = [] } = useSelector(selectGroupAmbassador);
  const [loadingChallenge, setLoadingChallenge] = useState(true);

  const handleClick = (
    startDate: Date,
    finishDate: Date,
    hour: string,
    minutes: string
  ) => {
    const dateStart = new Date(
      `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }/${startDate.getDate()} ${hour}:${minutes}`
    );

    const dateFinish = new Date(
      `${finishDate.getFullYear()}/${
        finishDate.getMonth() + 1
      }/${finishDate.getDate()} 22:59:59`
    );

    setStartDate(dateStart);
    setFinishDate(dateFinish);
  };

  const handleImagen = (select: Array<any>, content: string) => {
    switch (content) {
      case 'photo':
        setImage(select);
        setEditImage(true);
        break;
      case 'multimedia':
        setMultimediaContent(select);
        setEditMultimedia(true);
        break;
    }
  };

  const handleParticipation = (
    group: Array<Igroup>,
    ambassadors: Array<Igroup>
  ) => {
    const groupsIds = group.map(select => select.id);
    const ambassadorsIds = ambassadors.map(select => select.id);
    setGroup(groupsIds);
    setAmbassadors(ambassadorsIds);
  };

  const getGroup = async (name: string) => {
    await getGroupAmbassador(currentUser?.token, 1, 500, name)
      .then(response => {
        const group = sortGroups(response.data.data.groups.docs);
        const ambassadors = sortGroups(response.data.data.ambassadors.docs);
        const groupAmbassador: IGroupAmssador = {
          groups: group,
          ambassador: ambassadors
        };
        dispatch(setData(groupAmbassador));
        dispatch(setLoadingGroupAmbassador(false));
      })
      .catch(error => {
        dispatch(setLoadingGroupAmbassador(false));
      });
  };

  const getChallengeData = async () => {
    try {
      const { data } = await getChallengeById(
        currentUser?.token,
        parseInt(challengeId),
        false
      );

      if (data) {
        setChallenge(data.data);
        setLoadingChallenge(false);
      }
    } catch (error) {
      window.location.href = '/creators';
    }
  };

  useEffect(() => {
    if (challenge) {
      setStartDate(
        challenge?.startDate ? new Date(challenge.startDateUTC) : ''
      );
      setFinishDate(
        challenge?.finishDate ? new Date(challenge.finishDate) : ''
      );
      setHourt(new Date(challenge.startDateUTC).getHours());
      setMinutes(new Date(challenge.startDateUTC).getMinutes());
      setGroup(challenge?.groupsIds ? challenge.groupsIds : []);
      setAmbassadors(challenge?.ambassadorsIds ? challenge.ambassadorsIds : []);
      setImage(challenge?.photo ? [challenge.photo] : ['']);
      setIsDisabled(
        challenge.status !== 'completed' && action === 'edit' ? false : true
      );
      setCheckMultimedia(
        challenge?.multimedia?.picturesUrls?.length > 0 ||
          challenge?.multimedia?.videoUrl != null
      );
      if (challenge?.multimedia?.picturesUrls?.length > 0) {
        setMultimediaContent(challenge?.multimedia?.picturesUrls?.length);
      }
      if (challenge?.multimedia?.videoUrl != null) {
        setMultimediaContent(challenge?.multimedia?.videoUrl);
      }
    }
  }, [challenge]);

  if (loadingChallenge) {
    return <LoadingState height="230px" width="100%" />;
  }

  const onSubmit = async (challengeData: any) => {
    setLoader(true);
    if (image.length === 0) {
      setBackendError(t('challenge:image_required'));
      setLoader(false);
      return;
    }
    if (startDate == '' && finishDate == '') {
      setBackendError(t('challenge:error_date'));
      setLoader(false);
      return;
    }

    if (group.length == 0 && ambassadors.length == 0) {
      setBackendError(t('challenge:error_info_ambassador'));
      setLoader(false);
      return;
    }
    if (
      !challengeData.facebook &&
      !challengeData.twitter &&
      !challengeData.linkedin &&
      !challengeData.instagram &&
      !challengeData.tiktok &&
      !challengeData.snapchat &&
      !challengeData.youtube &&
      !challengeData.threads &&
      !challengeData.others
    ) {
      setErrorSocialNetwork(t('challenge:error_social_network'));
      setBackendError(t('challenge:error_social_network'));
      setLoader(false);
      return;
    }

    if (!challengeData.formatImage && !challengeData.formatVideo) {
      setErrorFormat(t('challenge:error_format'));
      setBackendError(t('challenge:error_format'));
      setLoader(false);
      return;
    }

    const finishDateNew = finishDate;
    const today = new Date();
    finishDateNew.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    if (finishDateNew < today) {
      setBackendError(t('challenge:end_date_today_error'));
      setLoader(false);
      return;
    }

    if (checkMultimedia && multimediaContent.length == 0) {
      setBackendError(t('challenge:error_multimedia'));
      setLoader(false);
      return;
    }

    if (editImage) {
      const namePhoto = generateRandAlphaNumStr();
      const uploadedPhoto = await awsUploadFileHelper(
        currentUser?.token,
        image,
        true,
        'CHALLENGE_IMG',
        namePhoto,
        t,
        setBackendError
      );
      if (uploadedPhoto) {
        challengeData.photo = getUrlS3('challenges', namePhoto);
      } else {
        setBackendError(t('validations:file_error'));
        setLoader(false);
        return;
      }
    } else {
      challengeData.photo = challenge?.photo;
    }

    let multimedia: any;
    if (editMultimedia) {
      if (challengeData.formatImage) {
        const multimediaFile = [];
        if (multimediaContent) {
          setUploadedMultimedia(false);
        }
        for (const file of multimediaContent) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            multimediaFile.push({ name });
            continue;
          }
          const nameMultimedia = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CHALLENGE_IMG',
            nameMultimedia,
            t,
            setBackendError
          );
          multimediaFile.push({ name: nameMultimedia });
        }
        multimedia = {
          picturesUrls: multimediaFile.map((file: any) =>
            getUrlS3('challenges', file?.name)
          )
        };
      }
      if (challengeData.formatVideo) {
        setUploadedMultimedia(false);
        const nameMultimedia = setFileNameHelper(multimediaContent, false);
        const uploadedTwitter = await awsUploadVideoHelper(
          currentUser?.token,
          multimediaContent,
          'CHALLENGE_IMG',
          nameMultimedia,
          t,
          setBackendError
        );
        if (uploadedTwitter) {
          setUploadedMultimedia(true);
          multimedia = {
            videoUrl: getUrlS3('challenges', nameMultimedia)
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    } else {
      if (challenge?.multimedia?.picturesUrls?.length > 0) {
        multimedia = { picturesUrls: challenge?.multimedia?.picturesUrls };
      } else if (challenge?.multimedia?.videoUrl != null) {
        multimedia = { videoUrl: challenge?.multimedia?.videoUrl };
      } else {
        multimedia = {
          picturesUrls: [],
          videoUrl: null
        };
      }
    }
    if (!checkMultimedia) {
      multimedia = {
        picturesUrls: [],
        videoUrl: null
      };
    }
    if (
      (checkMultimedia &&
        challengeData.formatImage &&
        !multimedia?.picturesUrls) ||
      (checkMultimedia && challengeData.formatVideo && !multimedia?.videoUrl)
    ) {
      setBackendError(t('challenge:error_multimedia'));
      setLoader(false);
      return;
    }

    challengeData.id = parseInt(challengeId);
    challengeData.startDate = new Date(
      `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }/${startDate.getDate()} ${hourt}:${minutes}`
    ).toString();
    challengeData.finishDate = new Date(
      `${finishDate.getFullYear()}/${
        finishDate.getMonth() + 1
      }/${finishDate.getDate()} 22:59:59`
    ).toString();

    challengeData.companyId = currentUser.user.companyId;
    challengeData.adminId = currentUser.user.id;
    challengeData.groupsIds = group;
    challengeData.ambassadorsIds = ambassadors;
    challengeData.format =
      challengeData.formatImage && challengeData.formatVideo
        ? 'video, image'
        : challengeData.formatImage
        ? 'image'
        : challengeData.formatVideo
        ? 'video'
        : null;
    challengeData.multimedia = multimedia;

    const { data, errors } = await updateChallenge(
      currentUser?.token,
      challengeData
    );

    if (data) {
      history.push('/creators', t('challenge:success_update'));
    }

    if (errors) {
      setBackendError(errors.data);
      return;
    }

    setLoader(false);
  };

  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <EditChallengeForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        handleClick={handleClick}
        groupsAmbassador={groupsAmbassador}
        getData={getGroup}
        handleParticipation={handleParticipation}
        handleImage={handleImagen}
        errorSocialNetwork={errorSocialNetwork}
        errorFormat={errorFormat}
        loader={loader}
        setHourt={setHourt}
        setMinutes={setMinutes}
        sHour={hourt}
        sMinutes={minutes}
        challenge={challenge}
        setBackendError={setBackendError}
        isDisabled={isDisabled}
        checkMultimedia={checkMultimedia}
        setCheckMultimedia={setCheckMultimedia}
        uploadedMultimedia={uploadedMultimedia}
      />
    </>
  );
};

export default EditChallengePage;
