import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SuccessAlert, ErrorAlert } from 'components/Alert';
import { ImageCard, VideoCard, FacebookCard, EmbedLkCard } from './PostCard';
import { PostActionsContext } from './PostActions';

import { SocialNetworkCampaign, Points } from 'types/appTypes';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import { networkCampaign, networkCampaignNull } from 'Utils/CampaignsDetail';
import {
  selectUser,
  selectUserInfo,
  setUserInfo
} from 'redux/slices/userSlice';
import { getCampaingsActived } from 'services/rest/Campaings';
import { setListCampaigns } from 'redux/slices/Campaigns/CampaignsSlice';
import { selectCompany, setLoading } from 'redux/slices/Company/CompanySlice';
import { getInfoUser } from 'services/rest/logout';
import { setInvalidTokenCurrent } from 'redux/slices/InvalidToken/InvalidTokenSlice';

export interface PostsListProps {
  campaign?: ICampaigns;
  campaignStatus: any;
  getRankingData?: any;
}

export const NetworkCard: React.FC<SocialNetworkCampaign> = props => {
  const { provider, contentType } = props;

  let response = null;
  if (provider === 'facebook') {
    response = <FacebookCard {...props} />;
  } else {
    if (['image', 'text'].includes(contentType)) {
      response = <ImageCard {...props} />;
    }
    if (contentType === 'video') {
      response = <VideoCard {...props} />;
    }
    if (contentType == 'pdf' || contentType == 'share') {
      response = <EmbedLkCard {...props} />;
    }
  }
  return response;
};

const PostsList: React.FC<PostsListProps> = props => {
  const { campaign, campaignStatus, getRankingData } = props;
  const [successAlertVisible, setSuccessAlertVisible] = useState('');
  const [errorAlertVisible, setErrorAlertVisible] = useState('');

  const { infoUser } = useSelector(selectUserInfo);
  const { currentUser } = useSelector(selectUser);
  const { company } = useSelector(selectCompany);
  const dispatch = useDispatch();

  const { t } = useTranslation(['campaigns_page']);

  const getUserData = async () => {
    const { data: dataUser } = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token
    );
    dispatch(setUserInfo(dataUser));
  };

  useEffect(() => {
    getUserData();
    dispatch(setInvalidTokenCurrent(''));
  }, []);

  const getData = async () => {
    dispatch(setLoading(true));
    const { data } = await getCampaingsActived(currentUser?.token, false);
    dispatch(setListCampaigns(data.data));
    dispatch(setLoading(false));
  };

  const onDeletePost = () => {};
  const onAcceptPost = () => {
    setSuccessAlertVisible(t('post_accepted'));
    getData();
  };
  const onProgramPost = () => {
    setSuccessAlertVisible(t('post_programmed'));
    getData();
  };
  const onPublishPost = () => {
    setSuccessAlertVisible(t('post_published'));
    getData();
  };

  const onErrorPost = (text: string) => {
    setErrorAlertVisible(text);
    getUserData();
  };

  //Get uid of the public

  const uidFacebook = campaign?.participations[0]?.facebook
    ? company?.viewPostUrl.facebook +
      campaign?.participations[0].networks.facebook.uid
    : '';

  const uidTwitter = campaign?.participations[0]?.twitter
    ? company?.viewPostUrl.twitter +
      campaign?.participations[0].networks.twitter.uid
    : '';

  const uidLinkedin = campaign?.participations[0]?.linkedin
    ? company?.viewPostUrl.linkedin +
      campaign?.participations[0].networks.linkedin.uid
    : '';

  const uidInstagram = campaign?.participations[0]?.instagram
    ? company?.viewPostUrl.instagram +
      campaign?.participations[0].networks.instagram.uid
    : '';

  const uidTiktok = campaign?.participations[0]?.tiktok
    ? company?.viewPostUrl.tiktok +
      campaign?.participations[0].networks.tiktok.uid
    : '';

  //Point get the of ambassador
  const pointsEngagementFacebook = campaign?.participations[0]?.facebook
    ? campaign?.participations[0].networks.facebook.pointsEngagement
    : ({} as Points);

  const pointsEngagementTwitter = campaign?.participations[0]?.twitter
    ? campaign?.participations[0].networks.twitter.pointsEngagement
    : ({} as Points);

  const pointsEngagementLinkedin = campaign?.participations[0]?.linkedin
    ? campaign?.participations[0].networks.linkedin.pointsEngagement
    : ({} as Points);

  const pointsEngagementInstagram = campaign?.participations[0]?.instagram
    ? campaign?.participations[0].networks.instagram.pointsEngagement
    : ({} as Points);

  const pointsEngagementTiktok = campaign?.participations[0]?.tiktok
    ? campaign?.participations[0].networks.tiktok.pointsEngagement
    : ({} as Points);

  const pointsEngagementWhatsapp = campaign?.participations[0]?.whatsapp
    ? campaign?.participations[0].networks.whatsapp.pointsEngagement
    : ({} as Points);

  //Status of participation of ambassador

  const statusFacebook = campaign?.participations[0]?.networks?.facebook
    ? campaign?.participations[0].networks.facebook.status
    : '';

  const statusTwitter =
    campaign?.participations[0]?.networks?.twitter ||
    campaign?.participations[0]?.networks?.twitter?.status?.includes('accepted')
      ? campaign?.participations[0].networks.twitter.status
      : '';

  const statusLinkedin =
    campaign?.participations[0]?.networks?.linkedin ||
    campaign?.participations[0]?.networks?.linkedin?.status?.includes(
      'accepted'
    )
      ? campaign?.participations[0].networks.linkedin.status
      : '';

  const statusInstagram = campaign?.participations[0]?.networks?.instagram
    ? campaign?.participations[0].networks.instagram.status
    : '';

  const statusTiktok = campaign?.participations[0]?.networks?.tiktok
    ? campaign?.participations[0].networks.tiktok.status
    : '';

  const statusWhatsapp = campaign?.participations[0]?.networks?.whatsapp
    ? campaign?.participations[0].networks.whatsapp.status
    : '';

  //Point of pactipation of ambassador

  const pointFacebook = campaign?.participations[0]?.facebook
    ? campaign?.participations[0].networks.facebook.totalPoints
    : 0;

  const pointTwitter = campaign?.participations[0]?.twitter
    ? campaign?.participations[0].networks.twitter.totalPoints
    : 0;

  const pointLinkedin = campaign?.participations[0]?.linkedin
    ? campaign?.participations[0].networks.linkedin.totalPoints
    : 0;

  const pointInstagram = campaign?.participations[0]?.instagram
    ? campaign?.participations[0].networks.instagram.totalPoints
    : 0;
  const pointTiktok = campaign?.participations[0]?.tiktok
    ? campaign?.participations[0].networks.tiktok.totalPoints
    : 0;

  const pointWhatsapp = campaign?.participations[0]?.whatsapp
    ? campaign?.participations[0].networks.whatsapp.totalPoints
    : 0;

  //List component of participation
  const facebookCampaign: SocialNetworkCampaign = campaign?.includesFacebook
    ? networkCampaign(
        campaign,
        campaign?.contentPerSocialNetwork?.facebook.type
          ? campaign?.contentPerSocialNetwork?.facebook.type
          : '',
        campaign?.contentPerSocialNetwork?.facebook.content
          ? campaign?.contentPerSocialNetwork?.facebook.content
          : '',
        campaign?.contentPerSocialNetwork?.facebook.picturesUrls
          ? campaign?.contentPerSocialNetwork?.facebook.picturesUrls
          : [],
        campaign?.contentPerSocialNetwork?.facebook.videoUrl
          ? campaign?.contentPerSocialNetwork?.facebook.videoUrl
          : '',
        '',
        '',
        'facebook',
        campaign?.pointsValue?.facebook,
        Number(pointFacebook),
        pointsEngagementFacebook,
        infoUser.linkedFacebook,
        infoUser.social_network?.networks?.facebook?.invalidToken,
        statusFacebook,
        campaign?.participations[0]?.facebook,
        uidFacebook.replace('#UID', '')
      )
    : networkCampaignNull();

  const twitterCampaign: SocialNetworkCampaign = campaign?.includesTwitter
    ? networkCampaign(
        campaign,
        campaign?.contentPerSocialNetwork.twitter.type
          ? campaign?.contentPerSocialNetwork.twitter.type
          : '',
        campaign?.contentPerSocialNetwork.twitter.content
          ? campaign?.contentPerSocialNetwork.twitter.content
          : '',
        campaign?.contentPerSocialNetwork.twitter.picturesUrls
          ? campaign?.contentPerSocialNetwork.twitter.picturesUrls
          : [],
        campaign?.contentPerSocialNetwork.twitter.videoUrl
          ? campaign?.contentPerSocialNetwork.twitter.videoUrl
          : '',
        '',
        '',
        'twitter',
        campaign?.pointsValue.twitter,
        Number(pointTwitter),
        pointsEngagementTwitter,
        infoUser.linkedTwitter,
        infoUser.social_network?.networks?.twitter?.invalidToken,
        statusTwitter,
        campaign?.participations[0]?.twitter,
        uidTwitter.replace('#UID', ''),
        campaign?.contentPerSocialNetwork.twitter.content2
          ? campaign?.contentPerSocialNetwork.twitter.content2
          : '',
        campaign?.contentPerSocialNetwork.twitter.content3
          ? campaign?.contentPerSocialNetwork.twitter.content3
          : ''
      )
    : networkCampaignNull();

  const linkedinCampaign: SocialNetworkCampaign = campaign?.includesLinkedin
    ? networkCampaign(
        campaign,
        campaign?.contentPerSocialNetwork.linkedin.type
          ? campaign?.contentPerSocialNetwork.linkedin.type
          : '',
        campaign?.contentPerSocialNetwork.linkedin.content
          ? campaign?.contentPerSocialNetwork.linkedin.content
          : '',
        campaign?.contentPerSocialNetwork.linkedin.picturesUrls
          ? campaign?.contentPerSocialNetwork.linkedin.picturesUrls
          : [],
        campaign?.contentPerSocialNetwork.linkedin.videoUrl
          ? campaign?.contentPerSocialNetwork.linkedin.videoUrl
          : '',
        campaign?.contentPerSocialNetwork.linkedin.pdfUrl
          ? campaign?.contentPerSocialNetwork.linkedin.pdfUrl
          : '',
        campaign?.contentPerSocialNetwork.linkedin.urnLi
          ? campaign?.contentPerSocialNetwork.linkedin.urnLi
          : '',
        'linkedin',
        campaign?.pointsValue.linkedin,
        Number(pointLinkedin),
        pointsEngagementLinkedin,
        infoUser.linkedLinkedin,
        infoUser.social_network?.networks?.linkedin?.invalidToken,
        statusLinkedin,
        campaign?.participations[0]?.linkedin,
        uidLinkedin.replace('#UID', ''),
        campaign?.contentPerSocialNetwork.linkedin.content2
          ? campaign?.contentPerSocialNetwork.linkedin.content2
          : '',
        campaign?.contentPerSocialNetwork.linkedin.content3
          ? campaign?.contentPerSocialNetwork.linkedin.content3
          : ''
      )
    : networkCampaignNull();

  const instagramCampaign: SocialNetworkCampaign = campaign?.includesInstagram
    ? networkCampaign(
        campaign,
        campaign?.contentPerSocialNetwork.instagram.type
          ? campaign?.contentPerSocialNetwork.instagram.type
          : '',
        campaign?.contentPerSocialNetwork.instagram.content
          ? campaign?.contentPerSocialNetwork.instagram.content
          : '',
        campaign?.contentPerSocialNetwork.instagram.picturesUrls
          ? campaign?.contentPerSocialNetwork.instagram.picturesUrls
          : [],
        campaign?.contentPerSocialNetwork.instagram.videoUrl
          ? campaign?.contentPerSocialNetwork.instagram.videoUrl
          : '',
        '',
        '',
        'instagram',
        campaign?.pointsValue.instagram,
        Number(pointInstagram),
        pointsEngagementInstagram,
        infoUser.linkedInstagram,
        infoUser.social_network?.networks?.instagram?.invalidToken,
        statusInstagram,
        campaign?.participations[0]?.instagram,
        uidInstagram.replace('#UID', '')
      )
    : networkCampaignNull();
  const tiktokCampaign: SocialNetworkCampaign = campaign?.includesTiktok
    ? networkCampaign(
        campaign,
        campaign?.contentPerSocialNetwork.tiktok.type
          ? campaign?.contentPerSocialNetwork.tiktok.type
          : '',
        campaign?.contentPerSocialNetwork.tiktok.content
          ? campaign?.contentPerSocialNetwork.tiktok.content
          : '',
        campaign?.contentPerSocialNetwork.tiktok.picturesUrls
          ? campaign?.contentPerSocialNetwork.tiktok.picturesUrls
          : [],
        campaign?.contentPerSocialNetwork.tiktok.videoUrl
          ? campaign?.contentPerSocialNetwork.tiktok.videoUrl
          : '',
        '',
        '',
        'tiktok',
        campaign?.pointsValue.tiktok,
        Number(pointTiktok),
        pointsEngagementTiktok,
        infoUser.linkedTiktok,
        infoUser.social_network?.networks?.tiktok?.invalidToken,
        statusTiktok,
        campaign?.participations[0]?.tiktok,
        uidTiktok?.replace('#UID', ''),
        campaign?.contentPerSocialNetwork.tiktok.content2
          ? campaign?.contentPerSocialNetwork.tiktok.content2
          : '',
        campaign?.contentPerSocialNetwork.tiktok.content3
          ? campaign?.contentPerSocialNetwork.tiktok.content3
          : ''
      )
    : networkCampaignNull();
  const whatsappCampaign: SocialNetworkCampaign = campaign?.includesWhatsapp
    ? networkCampaign(
        campaign,
        campaign?.contentPerSocialNetwork.whatsapp.type
          ? campaign?.contentPerSocialNetwork.whatsapp.type
          : '',
        campaign?.contentPerSocialNetwork.whatsapp.content
          ? campaign?.contentPerSocialNetwork.whatsapp.content
          : '',
        campaign?.contentPerSocialNetwork.whatsapp.picturesUrls
          ? campaign?.contentPerSocialNetwork.whatsapp.picturesUrls
          : [],
        campaign?.contentPerSocialNetwork.whatsapp.videoUrl
          ? campaign?.contentPerSocialNetwork.whatsapp.videoUrl
          : '',
        '',
        '',
        'whatsapp',
        campaign?.pointsValue.whatsapp,
        Number(pointWhatsapp),
        pointsEngagementWhatsapp,
        true,
        false,
        statusWhatsapp,
        campaign?.participations[0]?.whatsapp,
        ''
      )
    : networkCampaignNull();

  const postActions = {
    onDeletePost,
    onAcceptPost,
    onPublishPost,
    onProgramPost,
    onErrorPost,
    campaignStatus
  };

  return (
    <PostActionsContext.Provider value={postActions}>
      {successAlertVisible && (
        <SuccessAlert
          text={successAlertVisible}
          allowClose={true}
          onClose={() => setSuccessAlertVisible('')}
        />
      )}
      {errorAlertVisible && (
        <ErrorAlert
          text={errorAlertVisible}
          allowClose={true}
          onClose={() => setErrorAlertVisible('')}
        />
      )}
      {campaign?.includesFacebook ? (
        <NetworkCard {...facebookCampaign} getRankingData={getRankingData} />
      ) : (
        <></>
      )}
      {campaign?.includesTwitter ? (
        <NetworkCard
          {...twitterCampaign}
          campaign={campaign}
          getRankingData={getRankingData}
        />
      ) : (
        <></>
      )}
      {campaign?.includesLinkedin ? (
        <NetworkCard
          {...linkedinCampaign}
          campaign={campaign}
          getRankingData={getRankingData}
        />
      ) : (
        <></>
      )}
      {campaign?.includesTiktok ? (
        <NetworkCard
          {...tiktokCampaign}
          campaign={campaign}
          getRankingData={getRankingData}
        />
      ) : (
        <></>
      )}
      {campaign?.includesInstagram ? (
        <NetworkCard {...instagramCampaign} />
      ) : (
        <></>
      )}
      {campaign?.includesWhatsapp ? (
        <NetworkCard {...whatsappCampaign} />
      ) : (
        <></>
      )}
    </PostActionsContext.Provider>
  );
};

export default PostsList;
