import React, { useState } from 'react';
import { deleteSuggestedPost } from 'services/rest/SuggestedPost/deleteSuggestedPost';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {
  DivIcon,
  GridWrapper,
  MarginDiv,
  MarginStatus,
  MarginText,
  SocialNetworkTags
} from './Styled';
import iconTrash from 'images/icons/trash.png';
import iconEye from 'images/icons/eyes.svg';
import bonusApproved from 'images/icons/bonus_approved.svg';
import bonusReject from 'images/icons/bonus_reject.svg';
import Tag from 'components/Tag/Tag';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import EmptyCampaign from 'images/empty_states/empty_campaigns.png';
import { approveSuggestedPost } from 'services/rest/SuggestedPost/approveSuggestedPost';

export interface SuggestedPostCardProps {
  id: number;
  name: string;
  description: string;
  status: string;
  date: string;
  ambassadorFullName: string;
  setLoading: any;
  changeSuggestedPost: any;
  setChangeSuggestedPost: any;
  includesFacebook?: boolean;
  includesTwitter?: boolean;
  includesLinkedin?: boolean;
  includesInstagram?: boolean;
  includesWhatsapp?: boolean;
  includesTiktok?: boolean;
  isAdmin?: boolean;
  points?: number;
}

const SuggestedPostCard: React.FC<SuggestedPostCardProps> = props => {
  const {
    id,
    name,
    description,
    status,
    date,
    ambassadorFullName,
    setLoading,
    changeSuggestedPost,
    setChangeSuggestedPost,
    includesFacebook = false,
    includesTwitter = false,
    includesLinkedin = false,
    includesInstagram = false,
    includesWhatsapp = false,
    includesTiktok = false,
    isAdmin = false,
    points
  } = props;
  const { currentUser } = useSelector(selectUser);
  const { t } = useTranslation(['campaign_card', 'bonus']);
  const [showPoints, setShowPoints] = useState(false);
  const timeLine = (date: any) => {
    date = moment(date);
    const month = date.format('MMM');
    const day = date.format('D');
    return `${month} ${day}`;
  };

  const statusText = (status: any) => {
    let statusNew;
    if (status === 'activated') {
      statusNew = t('campaign_card:active_campaign');
    }
    if (status === 'completed') {
      statusNew = t('campaign_card:completed_campaign');
    }
    if (status === 'pending') {
      statusNew = t('campaign_card:pending_campaign');
    }
    if (status === 'paused') {
      statusNew = t('campaign_card:paused_campaign');
    }
    return statusNew;
  };

  const onSubmitDelete = async (suggestedPostId: any) => {
    setLoading(true);
    const data = await deleteSuggestedPost(currentUser?.token, suggestedPostId);
    if (data) {
      setChangeSuggestedPost(!changeSuggestedPost);
    }
  };
  const onSubmitApprove = async (suggestedPostId: any, points: any) => {
    setLoading(true);
    const data = await approveSuggestedPost(
      currentUser?.token,
      suggestedPostId,
      points
    );
    if (data) {
      console.log(data);
    }
  };
  const onSubmitReject = async (suggestedPostId: number, message: any) => {
    setLoading(true);
    setShowPoints(true);
  };

  const confirmAlert = (suggestedPostId: number) => {
    Swal.fire({
      text: t('campaign_card:confirmation_message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmitDelete(suggestedPostId);
      }
    });
  };

  const go = (id: number) => {
    window.open(`/suggested-posts/${id}/detail`, '_blank', 'noreferrer');
  };

  const rejectAlert = (suggestedPostId: number) => {
    Swal.fire({
      title: t('bonus:reject_bonus_question'),
      icon: 'warning',
      customClass: {
        inputLabel: 'custom-swal-margin'
      },
      html: `<div>
      <label><b>${t('bonus:reject_bonus_reasons')}</b></label>
      </br>
      <textarea id='reject_reason' name="textarea" class="textArea-bonus" rows="3" cols="50" placeholder="${t(
        'bonus:place_holder_reject'
      )}"></textarea>    
      </div>`,

      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true,
      preConfirm: async () => {
        const reasonContent = document.getElementById('reject_reason') as any;
        if (reasonContent?.value.length > 280) {
          Swal.showValidationMessage(t('bonus:limit_reason'));
        } else {
          onSubmitReject(suggestedPostId, reasonContent?.value);
        }
      }
    });
  };

  const approveAlert = (suggestedPostId: number) => {
    let defaultPoints = 10;
    const htmlPoints = 'type="number"';

    Swal.fire({
      title: t('bonus:approve_bonus_question'),
      icon: 'warning',
      customClass: {
        inputLabel: 'custom-swal-margin'
      },
      html: `
      <p><b>${t('bonus:points_for_bonus')}</b></p>
      <input id="points" ${htmlPoints} class="bonus-box" placeholder="${t(
        'bonus:points_placeholder'
      )}">`,

      showCancelButton: true,
      confirmButtonText: t('campaign_card:yes'),
      cancelButtonText: t('campaign_card:not'),
      reverseButtons: true,
      preConfirm: async () => {
        const pointsValue = document.getElementById('points') as any;
        defaultPoints =
          !isNaN(pointsValue?.value) && pointsValue?.value > 0
            ? pointsValue?.value
            : defaultPoints;
        onSubmitApprove(suggestedPostId, defaultPoints);
      }
    });
  };

  const totalPoints = points ? points : 0;

  return (
    <>
      <GridWrapper className="hide-on-mobile activated-campaign content-box content-box-horizontal flex-box-container hoverable small">
        <div className="content-box-header content-img">
          <Link to={`/suggested-posts/${id}/detail`}>
            <img src={EmptyCampaign} alt="new" />
          </Link>
          <SocialNetworkTags>
            {includesFacebook && <Tag variant="bottom" network="facebook" />}
            {includesTwitter && <Tag variant="bottom" network="twitter" />}
            {includesLinkedin && <Tag variant="bottom" network="linkedin" />}
            {includesInstagram && <Tag variant="bottom" network="instagram" />}
            {includesWhatsapp && <Tag variant="bottom" network="whatsapp" />}
            {includesTiktok && <Tag variant="bottom" network="tiktok" />}
          </SocialNetworkTags>
        </div>
        <MarginText className="m-auto">
          <Link to={`/suggested-posts/${id}/detail`}>
            <p className="time-line">{timeLine(date)}</p>
            <div className={name.length > 50 ? 'overflow-elipsis' : ''}>
              <h2>{name}</h2>
            </div>
          </Link>
        </MarginText>
        <div className="content-box-body campaign-stats" onClick={() => go(id)}>
          {isAdmin ? (
            <MarginStatus className="division-right">
              <p>{ambassadorFullName}</p>
              <h3>Embajador</h3>
            </MarginStatus>
          ) : (
            <MarginStatus className="division-right"></MarginStatus>
          )}
          {/* <MarginDiv>
            {status === 'pending' ? (
              <>
                <p>Sin asignar</p>
                <h3>Puntos</h3>
              </>
            ) : (
              <MarginStatus className="division-right">
                <p>{totalPoints}</p>
                <h3>Puntos</h3>
              </MarginStatus>
            )}
          </MarginDiv> */}
        </div>
        <DivIcon>
          {isAdmin ? (
            status === 'pending' ? (
              <>
                <div>
                  <Link to={`/suggested-posts/${id}/detail`}>
                    <img src={iconEye} />
                  </Link>
                </div>
                {/* <div>
                  <img src={bonusApproved} onClick={() => approveAlert(id)} />
                </div>

                <div>
                  <img src={bonusReject} onClick={() => rejectAlert(id)} />
                </div> */}
                <div>
                  <img src={iconTrash} onClick={() => confirmAlert(id)} />
                </div>
              </>
            ) : (
              <>
                <div>
                  <Link to={`/suggested-posts/${id}/detail`}>
                    <img src={iconEye} />
                  </Link>
                </div>
              </>
            )
          ) : status === 'pending' ? (
            <>
              <div>
                <Link to={`/suggested-posts/${id}/detail`}>
                  <img src={iconEye} />
                </Link>
              </div>

              <div>
                <img src={iconTrash} onClick={() => confirmAlert(id)} />
              </div>
            </>
          ) : (
            <>
              <div>
                <Link to={`/suggested-posts/${id}/detail`}>
                  <img src={iconEye} />
                </Link>
              </div>
            </>
          )}
        </DivIcon>
      </GridWrapper>
    </>
  );
};

export default SuggestedPostCard;
