import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/buttons/Button';
import Calendar from 'components/Calendar';
import Icon from 'components/icons/Icon';
import EditorInput from 'components/inputs/EditorInput';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import UploadFileCropper from 'components/inputs/UploadFileCropper';
import UploadFileImageSimple from 'components/inputs/UploadFile/UploadFileImageSimple';
import UploadFileMultipleImage from 'components/inputs/UploadFile/UploadFileMultipleImage';
import UploadFileVideo from 'components/inputs/UploadFile/UploadFileVideo';
import NavigationLink from 'components/links/NavigationLink';
import Spinner from 'components/Spinner/Spinner';
import { RequiredString } from 'helpers/yupHelper';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { Controller, FieldErrors, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import { CampaignData } from 'services/rest/Campaign/createCampaign';
import { Colors } from 'styles/Constants';
import { Hourt, Minutes } from 'Utils/Hourt';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import * as yup from 'yup';

import {
  Column,
  ErrorSpan,
  FlexSection,
  FormTextArea,
  FormWrapper,
  GridComponent,
  InputsWrapper,
  LabelForm,
  MarginButton,
  NetworkFlexContainer,
  NumberWrapper,
  SocialNetworkContent,
  TimeZoneCampaign,
  MarginAdminPage
} from './Styled';
import { facebookURLs } from 'Utils/FacebookURLs';
import { useSelector } from 'react-redux';

const https = 'https://';
const www = '(?:www.)';
const fb = 'facebook.com';
const no_mentions = /^(?:(?!\B@).\n*)+$/;
const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;

export interface CreateCampaignFormProps {
  onSubmit: (event: React.FormEvent) => void;
  errors: FieldErrors<CampaignData>;
  register: UseFormMethods['register'];
  setValue: any;
  values?: any;
  control: any;
  handleChange?: any;
  handleBlur?: any;
  handleClick?: any;
  handleParticipation: any;
  groupsAmbassador: IGroupAmssador;
  getData?: any;
  handleImage: any;
  handleTypeFile: any;
  suggestedPost: any;
  type: any;
  setImageTwitter: any;
  setImageLinkedin: any;
  setImageInstagram: any;
  setImageWhatsapp: any;
  setImageTiktok: any;
  loader: any;
  setHourt: any;
  setMinutes: any;
  sHour: any;
  sMinutes: any;
  setBackendError: any;
  contentLimits: any;
  invalidLimit: any;
  uploadedTwitter: boolean | null;
  uploadedLinkedin: boolean | null;
  uploadedInstagram: boolean | null;
  uploadedWhatsapp: boolean | null;
  uploadedTiktok: boolean | null;
}

const Option = (
  id: number,
  name: string,
  key: any,
  onChange: any,
  type: string,
  group: Array<Igroup>
) => {
  const select: Igroup = { id: id, name: name };
  const selectGroup = group.find(group => group.id == id);
  return (
    <div
      className="checkbok-wrapper"
      key={key}
      id={id.toString()}
      onClick={() => onChange(select, type)}
    >
      <input
        id={id.toString()}
        type="checkbox"
        onChange={() => onChange(select, type)}
        defaultChecked={selectGroup ? true : false}
        checked={selectGroup ? true : false}
      />
      <Icon
        className="icon-Group"
        name={type == 'group' ? `group` : 'single-user'}
        color="#9b9b9b"
        size="small"
      />
      <label className="label-groups">{name}</label>
    </div>
  );
};

export const userSchema = yup.object().shape({
  name: RequiredString(),
  description: RequiredString()
});

const EditSuggestedPostForm: React.FC<CreateCampaignFormProps> = props => {
  const {
    errors,
    onSubmit,
    register,
    handleClick,
    groupsAmbassador: { groups = [], ambassador = [] },
    getData,
    handleParticipation,
    handleImage,
    handleTypeFile,
    control,
    suggestedPost = {},
    setImageTwitter,
    setImageLinkedin,
    setImageInstagram,
    setImageWhatsapp,
    setImageTiktok,
    loader,
    setHourt,
    setMinutes,
    sHour,
    sMinutes,
    setBackendError,
    contentLimits,
    invalidLimit,
    uploadedTwitter,
    uploadedLinkedin,
    uploadedInstagram,
    uploadedWhatsapp,
    uploadedTiktok
  } = props;

  const { t } = useTranslation(['campaigns_page']);
  const [onShow, setShow] = useState(true);
  const refOne = useRef<HTMLDivElement>(null);
  const [onURL, setURL] = useState({ URL: '', isInputValid: true });
  const [onLinkedin, setLinkedin] = useState({ URL: '', isInputValid: true });
  const [onTwitter, setTwitter] = useState({ URL: '', isInputValid: true });
  const [onTiktok, setTiktok] = useState({ URL: '', isInputValid: true });
  const [dateStart, setStartDate] = useState(new Date());
  const [dateEnd, setEndDate] = useState(new Date());
  const [checked, setChecked] = useState({
    facebook: suggestedPost?.includesFacebook,
    twitter: suggestedPost?.includesTwitter,
    linkedin: suggestedPost?.includesLinkedin,
    instagram: suggestedPost?.includesInstagram,
    whatsapp: suggestedPost?.includesWhatsapp,
    tiktok: suggestedPost?.includesTiktok
  });

  const [file, setFile] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    whatsapp: false,
    tiktok: false
  });

  const [type, setType] = useState({
    twitter: suggestedPost.contentPerSocialNetwork?.twitter?.type,
    linkedin: suggestedPost.contentPerSocialNetwork?.linkedin?.type,
    instagram: suggestedPost.contentPerSocialNetwork?.instagram?.type,
    whatsapp: suggestedPost.contentPerSocialNetwork?.whatsapp?.type,
    tiktok: suggestedPost.contentPerSocialNetwork?.tiktok?.type
  });

  const [validationInput, setValidationInput] = useState('');
  const [changeType, setChangeType] = useState({
    twitter: false,
    instagram: false,
    linkedin: false,
    whatsapp: false,
    tiktok: false
  });

  const currentDate = new Date();
  const [inputParticipation, setInputParticipation] = useState('');

  const defaultCurrentTime = (param: any) => {
    const date = new Date();
    const currentTime = date.toLocaleTimeString('en-US', { hour12: false });
    const splitTime = currentTime.split(':');
    return param == 'hour' ? parseInt(splitTime[0]) : parseInt(splitTime[1]);
  };

  const [group, setGroup] = useState<Array<Igroup>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<Igroup>>([]);

  const [twitterValue, setTwitterValue] = useState(
    suggestedPost?.contentPerSocialNetwork?.twitter?.content
  );
  const [linkedinValue, setLinkedinValue] = useState(
    suggestedPost?.contentPerSocialNetwork?.linkedin?.content
  );
  const [tiktokValue, setTiktokValue] = useState(
    suggestedPost?.contentPerSocialNetwork?.tiktok?.content
  );

  const { company } = useSelector(selectCompany);
  const timezone = company?.timezone;

  useEffect(() => {
    handleParticipation(group, ambassadors);
  }, [group, ambassadors]);

  useEffect(() => {
    handleTypeFile(type);
  }, [type]);

  useEffect(() => {
    getData(inputParticipation);
  }, [inputParticipation]);

  useEffect(() => {
    document.addEventListener('click', closeOpenShow, true);
  }, []);

  useEffect(() => {
    getData('');
  }, []);

  useEffect(() => {
    setMinutes(defaultCurrentTime('minutes'));
    setHourt(defaultCurrentTime('hour'));
  }, []);

  const closeOpenShow = (e: any) => {
    if (refOne.current && onShow && !refOne.current.contains(e.target)) {
      setShow(true);
    }
  };

  const handlRemoveGroup = (idItem: any) => {
    const newGroup = group.filter(group => group.id != idItem);
    setGroup(newGroup);
  };

  const handlRemoveAmbbassador = (idItem: any) => {
    const newGroup = ambassadors.filter(group => group.id != idItem);
    setAmbassadors(newGroup);
  };

  const handleAddGroup = (select: Igroup, type: string) => {
    switch (type) {
      case 'group':
        if (group.length == 0) {
          setGroup([select]);
        } else {
          const selectGroup = group.find(group => group.id == select.id);
          if (selectGroup) {
            handlRemoveGroup(selectGroup.id);
            setInputParticipation('');
            break;
          }
          setGroup([...group, select]);
        }
        setInputParticipation('');
        break;
      case 'ambassadors':
        if (ambassadors.length == 0) {
          setAmbassadors([select]);
        } else {
          const selectGroup = ambassadors.find(group => group.id == select.id);
          if (selectGroup?.id == select.id) {
            handlRemoveAmbbassador(selectGroup.id);
            setInputParticipation('');
            break;
          }
          setAmbassadors([...ambassadors, select]);
        }
        setInputParticipation('');
        break;
    }
  };

  const hourt = Hourt.map(hourt => {
    return { label: hourt, value: hourt };
  });

  const minutes = Minutes.map(minutes => {
    return { label: minutes, value: minutes };
  });

  const onSelectedTypeFile = (socialNetwork: string, content: boolean) => {
    const socialNetworkSelected = file as any;
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: content
    };
    setFile(socialNetworks);
  };

  const onSelectedType = (socialNetwork: string, typeContent: string) => {
    const socialNetworkSelected = type as any;
    let socialNetworks;
    if (socialNetworkSelected[socialNetwork] == typeContent) {
      socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: 'text'
      };
    } else {
      socialNetworks = {
        ...socialNetworkSelected,
        [socialNetwork]: typeContent
      };
    }
    setType(socialNetworks);
  };

  const onSelectedSocialNetwork = (socialNetwork: string) => {
    const socialNetworkSelected = checked as any;

    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: !sns
    };
    setChecked(socialNetworks);
    socialNetwork == 'twitter' || socialNetwork == 'linkedin'
      ? onSelectedType(socialNetwork, 'text')
      : '';
  };

  const onSelectCalendar = (startDate: Date, endDate: Date) => {
    if (dateStart == null) {
      setStartDate(startDate);
    }

    if (dateEnd == null) {
      setEndDate(endDate);
    }
    handleClick(
      startDate,
      endDate,
      defaultCurrentTime('hour'),
      defaultCurrentTime('minutes')
    );
  };

  const onChange = (e: any) => {
    setInputParticipation(e.target.value);
    getData(e.target.value);
  };

  const onChangeTypeContent = (content: string, type: string) => {
    onSelectedTypeFile(content, type == 'video' ? true : false);
    onSelectedType(content, type);
    const socialNetworkSelected = file as any;
    const socialNetworks = {
      ...socialNetworkSelected,
      [content]: true
    };
    setChangeType(socialNetworks);
  };

  const onChangeHourtMinute = (hour: any, minutes: any) => {
    setHourt(hour);
    setMinutes(minutes);
  };

  const regextValidation = (URL: string, test: any) => {
    const regex = new RegExp(test);
    return regex.test(URL);
  };

  const updateUrlVal = (event: any) => {
    setValidationInput('');
    const { value } = event.target;
    if (!regextValidation(value, https) || !value.startsWith(https)) {
      setValidationInput(t('https_errors'));
      return;
    }

    if (!regextValidation(value, www)) {
      setValidationInput(t('www_errors'));
      return;
    }

    if (!regextValidation(value, fb)) {
      setValidationInput(t('facebook_errors'));
      return;
    }

    if (value.length > 200) {
      setValidationInput(t('count_validation'));
      return;
    }

    const isInputValid = !value || facebookURLs(value);
    setValidationInput('');
    setURL({
      URL: value,
      isInputValid
    });
  };

  const updateUrlValLinkedin = (event: any) => {
    const isInputValid = !event || regextValidationLinkedin(event);

    setLinkedin({
      URL: event,
      isInputValid
    });
  };

  const updateUrlValTiktok = (event: any) => {
    const isInputValid = !event || regextValidationTiktok(event);

    setTiktok({
      URL: event,
      isInputValid
    });
  };

  const updateTwitterField = (event: any) => {
    const isInputValid = !event || regextValidationTwitter(event);
    setTwitter({
      URL: event,
      isInputValid
    });
  };

  const regextValidationLinkedin = (URL: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(URL);
  };

  const regextValidationTwitter = (URL: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(URL);
  };

  const regextValidationTiktok = (URL: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(URL);
  };

  useEffect(() => {
    if (type.twitter == 'image') {
      setImageTwitter(
        suggestedPost?.contentPerSocialNetwork?.twitter?.picturesUrls
      );
    }

    if (type.twitter == 'video') {
      setImageTwitter(
        suggestedPost?.contentPerSocialNetwork?.twitter?.videoUrl
      );
    }

    if (type.linkedin == 'image') {
      setImageLinkedin(
        suggestedPost?.contentPerSocialNetwork?.linkedin?.picturesUrls
      );
    }

    if (type.linkedin == 'video') {
      setImageLinkedin(
        suggestedPost?.contentPerSocialNetwork?.linkedin?.videoUrl
      );
    }

    if (type.linkedin == 'pdf') {
      setImageLinkedin(
        suggestedPost?.contentPerSocialNetwork?.linkedin?.pdfUrl
      );
    }

    if (type.instagram == 'image') {
      setImageInstagram(
        suggestedPost?.contentPerSocialNetwork?.instagram?.picturesUrls
      );
    }

    if (type.instagram == 'video') {
      setImageInstagram(
        suggestedPost?.contentPerSocialNetwork?.instagram?.videoUrl
      );
    }

    if (type.whatsapp == 'image') {
      setImageWhatsapp(
        suggestedPost?.contentPerSocialNetwork?.whatsapp?.picturesUrls
      );
    }

    if (type.whatsapp == 'video') {
      setImageWhatsapp(
        suggestedPost?.contentPerSocialNetwork?.whatsapp?.videoUrl
      );
    }

    if (type.tiktok == 'video') {
      setImageTiktok(suggestedPost?.contentPerSocialNetwork?.tiktok?.videoUrl);
    }
  }, []);

  return (
    <>
      <SectionWrapper className="hide-on-mobile">
        <NavigationLink
          text={t('suggested_post:back')}
          direction="back"
          to="/suggested-posts"
        />
        <MarginAdminPage>
          <h1>Detalle post sugerido</h1>
        </MarginAdminPage>
        <form onSubmit={onSubmit}>
          <FormWrapper>
            <InputsWrapper>
              <LabelForm>
                <div>
                  <label>{t('name_campaign')}</label>
                  <p>{t('suggestion_name')}</p>
                </div>
                <div>
                  <Input
                    type="text"
                    name="name"
                    defaultValue={suggestedPost.name}
                    ref={register}
                    error={errors.name?.message}
                    data-testid="name"
                    disabled={true}
                    max={150}
                    maxLength={150}
                    style={{ textTransform: 'capitalize' }}
                  />
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('description_campaign')}</label>
                </div>
                <div>
                  <TextArea
                    name="description"
                    ref={register}
                    error={errors.description?.message}
                    defaultValue={suggestedPost.description}
                    rows={8}
                    maxLength={500}
                    placeholder={t('suggestion_description')}
                    disabled={true}
                  />
                </div>
              </LabelForm>
              <LabelForm>
                <div>
                  <label>{t('social_networks_c')}</label>
                  <p>{t('suggestion_social_networks')}</p>
                </div>
                <div className="network">
                  {company?.company_permission.allowFacebook && (
                    <NetworkFlexContainer>
                      <Icon
                        size="small"
                        name="facebook"
                        color={Colors.facebook}
                      />
                      <Input
                        id="sna_facebook"
                        type="checkbox"
                        name="includesFacebook"
                        ref={register}
                        checked={checked.facebook}
                        onChange={() => onSelectedSocialNetwork('facebook')}
                        error={errors.includesFacebook?.message}
                        disabled={true}
                      />
                    </NetworkFlexContainer>
                  )}
                  <NetworkFlexContainer>
                    <Icon size="small" name="twitter" color={Colors.twitter} />
                    <Input
                      id="sna_twitter"
                      type="checkbox"
                      name="includesTwitter"
                      ref={register}
                      checked={checked.twitter}
                      disabled={true}
                      onChange={() => onSelectedSocialNetwork('twitter')}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="linkedin"
                      color={Colors.linkedin}
                    />
                    <Input
                      id="sna_linkedin"
                      type="checkbox"
                      name="includesLinkedin"
                      ref={register}
                      checked={checked.linkedin}
                      disabled={true}
                      onChange={() => onSelectedSocialNetwork('linkedin')}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="instagram"
                      color={Colors.instagram}
                    />
                    <Input
                      id="sna_instagram"
                      type="checkbox"
                      name="includesInstagram"
                      ref={register}
                      checked={checked.instagram}
                      disabled={true}
                      onChange={() => onSelectedSocialNetwork('instagram')}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon
                      size="small"
                      name="whatsapp"
                      color={Colors.whatsapp}
                    />
                    <Input
                      id="sna_whatsapp"
                      type="checkbox"
                      name="includesWhatsapp"
                      ref={register}
                      checked={checked.whatsapp}
                      disabled={true}
                      onChange={() => onSelectedSocialNetwork('whatsapp')}
                    />
                  </NetworkFlexContainer>
                  <NetworkFlexContainer>
                    <Icon size="small" name="tiktok" color={Colors.tiktok} />
                    <Input
                      id="sna_tiktok"
                      type="checkbox"
                      name="includesTiktok"
                      ref={register}
                      checked={checked.tiktok}
                      disabled={true}
                      onChange={() => onSelectedSocialNetwork('tiktok')}
                    />
                  </NetworkFlexContainer>
                </div>
              </LabelForm>
              <FormTextArea>
                <div className="marginLabel">
                  <label>{t('contents_sn')}</label>
                  <p>{t('suggestion_content')}</p>
                </div>
                <div>
                  {checked.facebook && (
                    <SocialNetworkContent>
                      <div>
                        <Icon
                          size="small"
                          name="facebook"
                          color={Colors.facebook}
                        />
                      </div>
                      <label>{t('url_post')}</label>
                      <Input
                        name="contentFacebook"
                        ref={register({
                          required: checked.facebook
                        })}
                        data-testid="name"
                        onChange={updateUrlVal}
                        defaultValue={
                          suggestedPost.contentPerSocialNetwork?.facebook
                            ?.content
                        }
                        error={
                          errors.contentFacebook?.type === 'required'
                            ? t('required_field')
                            : ''
                        }
                        type="text"
                        placeholder={t('place_holder_fb')}
                        disabled={true}
                      />
                      {validationInput && (
                        <ErrorSpan>{validationInput}</ErrorSpan>
                      )}
                      {(!onURL.isInputValid ||
                        errors.contentFacebook?.type == 'pattern') && (
                        <ErrorSpan>{t('error_url')}</ErrorSpan>
                      )}
                    </SocialNetworkContent>
                  )}
                  {checked.twitter && (
                    <SocialNetworkContent
                      className="with-file"
                      disabled={
                        suggestedPost?.status === 'completed' ||
                        suggestedPost?.status === 'activated'
                          ? true
                          : false
                      }
                    >
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="twitter"
                            color={Colors.twitter}
                          />
                        </div>
                        <label>{t('description_tweet')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentTwitter"
                            control={control}
                            rules={
                              !twitterValue
                                ? {
                                    required: checked.twitter,
                                    maxLength: contentLimits.twitter,
                                    pattern: no_characters
                                  }
                                : {
                                    required: false,
                                    maxLength: contentLimits.twitter,
                                    pattern: no_characters
                                  }
                            }
                            render={({
                              value = suggestedPost?.contentPerSocialNetwork
                                ?.twitter?.content,
                              onChange,
                              ref
                            }) => (
                              <EditorInput
                                isTwitter={true}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.twitter}
                                content={value}
                                onChange={e => {
                                  setTwitterValue(e);
                                  onChange(e);
                                  updateTwitterField(e);
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="marginFile">
                        {type.twitter == 'video' && (
                          <UploadFileVideo
                            icon="video"
                            name="contentPerSocialNetwork.twitter"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="twitter"
                            ref={register}
                            infoBox={t('info_box_video_twitter')}
                            uploaded={uploadedTwitter}
                            videosArray={
                              changeType.twitter
                                ? []
                                : [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.twitter?.videoUrl
                                  ]
                            }
                            disabled={true}
                          />
                        )}
                        {type.twitter == 'image' && (
                          <UploadFileMultipleImage
                            uploaded={uploadedTwitter}
                            icon="photo"
                            name="contentPerSocialNetwork.twitter"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="twitter"
                            ref={register}
                            infoBox={t('info_box_photo_twitter')}
                            disabled={true}
                            imagesArray={
                              suggestedPost.contentPerSocialNetwork?.twitter
                                ?.type == 'image'
                                ? suggestedPost.contentPerSocialNetwork?.twitter
                                    ?.picturesUrls
                                : []
                            }
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.linkedin && (
                    <SocialNetworkContent
                      className="with-file"
                      disabled={
                        suggestedPost?.status === 'completed' ? true : false
                      }
                    >
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="linkedin"
                            color={Colors.linkedin}
                          />
                        </div>
                        <label>{t('description_post')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentLinkedin"
                            control={control}
                            rules={
                              !linkedinValue
                                ? {
                                    required: checked.linkedin,
                                    pattern: no_mentions,
                                    maxLength: contentLimits.linkedin
                                  }
                                : {
                                    required: false,
                                    maxLength: contentLimits.linkedin
                                  }
                            }
                            render={({
                              value = linkedinValue,
                              onChange,
                              ref
                            }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.linkedin}
                                content={value}
                                onChange={e => {
                                  setLinkedinValue(e);
                                  updateUrlValLinkedin(e);
                                  onChange(e);
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="marginFile">
                        {type.linkedin == 'pdf' && (
                          <UploadFileImageSimple
                            icon="pdf"
                            name="contentPerSocialNetwork.linkedin"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="linkedin"
                            maxFileSize={10000}
                            ref={register}
                            infoBox={t('info_box_pdf_linkedin')}
                            allowedFileTypes="application/pdf"
                            imagesArray={
                              suggestedPost.contentPerSocialNetwork?.linkedin
                                ?.type == 'pdf'
                                ? [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.linkedin?.pdfUrl
                                  ]
                                : []
                            }
                            disabled={true}
                          />
                        )}
                        {type.linkedin == 'video' && (
                          <UploadFileVideo
                            icon="video"
                            name="contentPerSocialNetwork.linkedin"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="linkedin"
                            ref={register}
                            maxFileSize={200000}
                            maxFileDuration={600}
                            infoBox={t('info_box_video_linkedin')}
                            uploaded={uploadedLinkedin}
                            videosArray={
                              changeType.linkedin
                                ? []
                                : [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.linkedin?.videoUrl
                                  ]
                            }
                            disabled={true}
                          />
                        )}
                        {type.linkedin == 'image' && (
                          <UploadFileMultipleImage
                            uploaded={uploadedLinkedin}
                            icon="photo"
                            name="contentPerSocialNetwork.linkedin"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="linkedin"
                            ref={register}
                            infoBox={t('info_box_photo_linkedin')}
                            imagesArray={
                              suggestedPost.contentPerSocialNetwork?.linkedin
                                ?.type == 'image'
                                ? suggestedPost.contentPerSocialNetwork
                                    ?.linkedin?.picturesUrls
                                : []
                            }
                            disabled={true}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.instagram && (
                    <SocialNetworkContent
                      className="with-file"
                      disabled={
                        suggestedPost?.status === 'completed' ||
                        suggestedPost?.status === 'activated'
                          ? true
                          : false
                      }
                    >
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="instagram"
                            color={Colors.instagram}
                          />
                        </div>
                        <label>{t('description_post')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentInstagram"
                            control={control}
                            rules={{
                              maxLength: contentLimits.instagram
                            }}
                            render={({
                              value = suggestedPost.contentPerSocialNetwork
                                ?.instagram?.content,
                              onChange,
                              ref
                            }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.instagram}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="marginFile">
                        {type.instagram == 'video' && (
                          <UploadFileVideo
                            icon="video"
                            name="contentPerSocialNetwork.instagram"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="instagram"
                            maxFileSize={30000}
                            maxFileDuration={60}
                            ref={register}
                            uploaded={uploadedInstagram}
                            infoBox={t('info_box_video_instagram')}
                            videosArray={
                              changeType.instagram
                                ? []
                                : [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.instagram?.videoUrl
                                  ]
                            }
                            disabled={true}
                          />
                        )}
                        {type.instagram == 'image' && (
                          <UploadFileImageSimple
                            icon="photo"
                            name="contentPerSocialNetwork.instagram"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="instagram"
                            maxFileSize={5000}
                            ref={register}
                            infoBox={t('info_box_photo_instagram')}
                            imagesArray={
                              suggestedPost.contentPerSocialNetwork?.instagram
                                ?.type == 'image'
                                ? [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.instagram?.picturesUrls
                                  ]
                                : []
                            }
                            disabled={true}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.whatsapp && (
                    <SocialNetworkContent
                      className="with-file"
                      disabled={
                        suggestedPost?.status === 'completed' ||
                        suggestedPost?.status === 'activated'
                          ? true
                          : false
                      }
                    >
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="whatsapp"
                            color={Colors.whatsapp}
                          />
                        </div>
                        <label>{t('description_wa')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentWhatsapp"
                            control={control}
                            rules={{
                              maxLength: contentLimits.whatsapp
                            }}
                            render={({
                              value = suggestedPost.contentPerSocialNetwork
                                ?.whatsapp?.content,
                              onChange,
                              ref
                            }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.whatsapp}
                                content={value}
                                onChange={e => {
                                  onChange(e);
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="marginFile">
                        {type.whatsapp == 'video' && (
                          <UploadFileVideo
                            icon="video"
                            name="contentPerSocialNetwork.whatsapp"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="whatsapp"
                            maxFileSize={30000}
                            maxFileDuration={30}
                            ref={register}
                            infoBox={t('info_box_video_whatsapp')}
                            uploaded={uploadedWhatsapp}
                            videosArray={
                              changeType.whatsapp
                                ? []
                                : [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.whatsapp?.videoUrl
                                  ]
                            }
                            disabled={true}
                          />
                        )}
                        {type.whatsapp == 'image' && (
                          <UploadFileImageSimple
                            icon="photo"
                            name="contentPerSocialNetwork.whatsapp"
                            handleImage={handleImage}
                            className="socialNetworkFile"
                            content="whatsapp"
                            maxFileSize={5000}
                            ref={register}
                            infoBox={t('info_box_photo_whatsapp')}
                            imagesArray={
                              suggestedPost.contentPerSocialNetwork?.whatsapp
                                ?.type == 'image'
                                ? [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.whatsapp?.picturesUrls
                                  ]
                                : []
                            }
                            disabled={true}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                  {checked.tiktok && (
                    <SocialNetworkContent
                      className="with-file"
                      disabled={
                        suggestedPost?.status === 'completed' ||
                        suggestedPost?.status === 'activated'
                          ? true
                          : false
                      }
                    >
                      <div className="SocialNetworkBox">
                        <div>
                          <Icon
                            size="small"
                            name="tiktok"
                            color={Colors.tiktok}
                          />
                        </div>
                        <label>{t('description_tk')}</label>
                        <div className="MarginEditText">
                          <Controller
                            name="contentTiktok"
                            control={control}
                            rules={{
                              required: checked.tiktok,
                              pattern: no_mentions,
                              maxLength: contentLimits.tiktok
                            }}
                            render={({
                              value = tiktokValue,
                              onChange,
                              ref
                            }) => (
                              <EditorInput
                                isTwitter={false}
                                ref={ref}
                                className="content-editor"
                                placeholder=""
                                maxCharacters={contentLimits.tiktok}
                                content={value}
                                onChange={e => {
                                  setTiktokValue(e);
                                  updateUrlValTiktok(e);
                                  onChange(e);
                                }}
                                disabled={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="marginFile">
                        {type.tiktok == 'video' && (
                          <UploadFileVideo
                            name="contentPerSocialNetwork.tiktok"
                            handleVideo={handleImage}
                            className="socialNetworkFile"
                            content="tiktok"
                            ref={register}
                            maxFileSize={200000}
                            maxFileDuration={600}
                            infoBox={t('info_box_video_tiktok')}
                            uploaded={uploadedTiktok}
                            videosArray={
                              changeType.tiktok
                                ? []
                                : [
                                    suggestedPost.contentPerSocialNetwork
                                      ?.tiktok?.videoUrl
                                  ]
                            }
                            disabled={true}
                          />
                        )}
                      </div>
                    </SocialNetworkContent>
                  )}
                </div>
              </FormTextArea>
            </InputsWrapper>
          </FormWrapper>
        </form>
      </SectionWrapper>
    </>
  );
};

export default EditSuggestedPostForm;
