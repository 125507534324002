import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Facebook as LoadingState } from 'react-content-loader';
import * as yup from 'yup';
import TwitterText from 'twitter-text';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers';

import { ISuggestedPost } from 'redux/slices/SuggestedPost/SuggestedPost.interface';
import {
  selectGroupAmbassador,
  setData,
  setLoadingGroupAmbassador
} from 'redux/slices/GroupAmbassador/GroupAmbassadorSlice';
import { selectUser } from 'redux/slices/userSlice';
import {
  Igroup,
  IGroupAmssador
} from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';

import { getSuggestedPostDetail } from 'services/rest/SuggestedPost/getSuggestedPostDetail';
import { createCampaign } from 'services/rest/Campaign/createCampaign';
import { getGroupAmbassador } from 'services/rest/GetGroupAmbassador/GetGroupAmbassador';

import { RequiredString } from 'helpers/yupHelper';
import {
  awsUploadFileHelper,
  setFileNameHelper
} from 'helpers/awsCampaignFileHelper';
import { awsUploadVideoHelper } from 'helpers/awsVideoHelper';
import { sortGroups } from 'helpers/groupsHelper';

import { getUrlS3 } from 'constants/setup.constants';

import { generateRandAlphaNumStr } from 'Utils/StringRandom';
import { facebookURLs } from 'Utils/FacebookURLs';

import { ErrorAlert } from 'components/Alert';
import { SuccessAlert } from 'components/Alert';

import EditSuggestedPostForm from './SuggestedPostDetailAmbassadorForm';

interface SuggestedPostParams {
  id: string;
}

const EditCampaignNewPage: React.FC = () => {
  const { t } = useTranslation(['campaigns_page', 'validations']);
  const dispatch = useDispatch();
  const { id } = useParams<SuggestedPostParams>();

  const [suggestedPost, setSuggestedPost] = useState<ISuggestedPost>();

  const [loadingCampaigns, setLoadingCampaigns] = useState(true);

  const userSchema = yup.object().shape({
    name: RequiredString().max(
      150,
      t('validations:length_error', { max: 150 })
    ),
    description: RequiredString().max(
      1800,
      t('validations:length_error', { max: 1800 })
    )
  });

  const { register, control, handleSubmit, setValue, errors } = useForm({
    resolver: yupResolver(userSchema)
  });

  useEffect(() => {
    dispatch(setLoadingGroupAmbassador(true));
    getData();
  }, []);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [hourt, setHourt] = useState<any>('00');
  const [minutes, setMinutes] = useState<any>('00');
  const history = useHistory();
  const [image, setImage] = useState<Array<any>>([]);
  const [imageTwitter, setImageTwitter] = useState<Array<any>>([]);
  const [imageLinkedin, setImageLinkedin] = useState<Array<any>>([]);
  const [imageInstagram, setImageInstagram] = useState<Array<any>>([]);
  const [imageWhatsapp, setImageWhatsapp] = useState<Array<any>>([]);
  const [imageTiktok, setImageTiktok] = useState<Array<any>>([]);

  const [group, setGroup] = useState<Array<number>>([]);
  const [ambassadors, setAmbassadors] = useState<Array<number>>([]);
  const [backendSuccess, setBackendSuccess] = useState<string | null>(null);

  const [type, setType] = useState({
    instagram: 'text',
    twitter: 'text',
    linkedin: 'text',
    tiktok: 'text',
    whatsapp: 'text'
  });

  const [loader, setLoader] = useState(false);

  const [editImage, setEditImage] = useState({
    photo: false,
    twitter: false,
    linkedin: false,
    instagram: false,
    tiktok: false,
    whatsapp: false
  });

  const { currentUser } = useSelector(selectUser);
  const { groupsAmbassador = [] } = useSelector(selectGroupAmbassador);

  const [contentLimits, setContentLimits] = useState({
    twitter: 280,
    linkedin: 1500,
    instagram: 700,
    tiktok: 150,
    whatsapp: 280
  });

  const [invalidLimit, setInvalidLimit] = useState({
    twitter: false,
    linkedin: false,
    instagram: false,
    tiktok: false,
    whatsapp: false
  });

  const no_characters = /^(?!.*[!@#$%^&*()\-_+={}[\]|\\;:'"ª|!· º\\,<.>/?]{5}).+/;
  const no_mentions = /^(?:(?!\B@).\n*)+$/;
  const no_links_mentions = /^(?!(https?:\/\/|ftp:\/\/|(?:www\.)?[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|mil|aero|asia|biz|cat|coop|info|int|jobs|mobi|museum|name|pro|tel|travel|arpa|root|nato|xxx|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|rs|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|ss|st|su|sv|sx|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)(\/[a-zA-Z0-9%_.~+-]*)*(\?[a-zA-Z0-9%_.~+=-]*)?(#[a-zA-Z0-9_.~+-]*)?)|(?:@[a-zA-Z0-9_]+|#\w+)).*$/;

  const [uploadedTwitter, setUploadedTwitter] = useState<boolean | null>(null);
  const [uploadedLinkedin, setUploadedLinkedin] = useState<boolean | null>(
    null
  );
  const [uploadedInstagram, setUploadedInstagram] = useState<boolean | null>(
    null
  );
  const [uploadedTiktok, setUploadedTiktok] = useState<boolean | null>(null);
  const [uploadedWhatsapp, setUploadedWhatsapp] = useState<boolean | null>(
    null
  );

  const getData = async () => {
    await getSuggestedPostDetail(id, currentUser?.token)
      .then(result => {
        setSuggestedPost(result.data.data);
        setLoadingCampaigns(false);
      })
      .catch(error => {
        setLoadingCampaigns(false);
      });
  };

  useEffect(() => {
    if (suggestedPost) {
      setHourt(new Date(suggestedPost.startDateUTC).getHours());
      setMinutes(new Date(suggestedPost.startDateUTC).getMinutes());
      setType({
        twitter: suggestedPost?.contentPerSocialNetwork?.twitter?.type
          ? suggestedPost?.contentPerSocialNetwork?.twitter?.type
          : 'text',
        linkedin: suggestedPost?.contentPerSocialNetwork?.linkedin?.type
          ? suggestedPost?.contentPerSocialNetwork?.linkedin?.type
          : 'text',
        instagram: suggestedPost?.contentPerSocialNetwork?.instagram?.type
          ? suggestedPost?.contentPerSocialNetwork?.instagram?.type
          : 'text',
        tiktok: suggestedPost?.contentPerSocialNetwork?.tiktok?.type
          ? suggestedPost?.contentPerSocialNetwork?.tiktok?.type
          : 'text',
        whatsapp: suggestedPost?.contentPerSocialNetwork?.whatsapp?.type
          ? suggestedPost?.contentPerSocialNetwork?.whatsapp?.type
          : 'text'
      });
      setImage(suggestedPost?.photo ? [suggestedPost.photo] : ['']);
    }
  }, []);

  if (loadingCampaigns) {
    return <LoadingState height="230px" width="100%" />;
  }

  const onSelectedSocialNetwork = (
    socialNetwork: string,
    file: any,
    isEdit: boolean
  ) => {
    const socialNetworkSelected = editImage as any;
    const sns = socialNetworkSelected[socialNetwork];
    const socialNetworks = {
      ...socialNetworkSelected,
      [socialNetwork]: isEdit
    };
    setEditImage(socialNetworks);
  };

  const handleClick = (
    startDate: Date,
    finishDate: Date,
    hour: any,
    minutes: any
  ) => {
    const dateStartString = `${startDate.getFullYear()}/${
      startDate.getMonth() + 1
    }/${startDate.getDate()} ${hour}:${minutes}:00`;

    const dateStart = new Date(dateStartString);

    const dateFinishString = `${finishDate.getFullYear()}/${
      finishDate.getMonth() + 1
    }/${finishDate.getDate()} 22:59:59`;

    const dateFinish = new Date(dateFinishString);

    setStartDate(dateStart);
    setFinishDate(dateFinish);
  };

  const handleImagen = (select: Array<any>, content: string) => {
    switch (content) {
      case 'photo':
        setImage(select);
        onSelectedSocialNetwork('photo', select, true);
        break;
      case 'twitter':
        setImageTwitter(select);
        onSelectedSocialNetwork('twitter', select, true);
        break;
      case 'linkedin':
        setImageLinkedin(select);
        onSelectedSocialNetwork('linkedin', select, true);
        break;
      case 'instagram':
        setImageInstagram(select);
        onSelectedSocialNetwork('instagram', select, true);
        break;
      case 'tiktok':
        setImageTiktok(select);
        onSelectedSocialNetwork('tiktok', select, true);
        break;
      case 'whatsapp':
        setImageWhatsapp(select);
        onSelectedSocialNetwork('whatsapp', select, true);
        break;
    }
  };

  const handleParticipation = (
    group: Array<Igroup>,
    ambassadors: Array<Igroup>
  ) => {
    const groupsIds = group.map(select => select.id);
    const ambassadorsIds = ambassadors.map(select => select.id);

    setGroup(groupsIds);
    setAmbassadors(ambassadorsIds);
  };

  const handleTypeFile = (body: any) => {
    setType(body);
  };

  const getGroup = async (name: string) => {
    await getGroupAmbassador(currentUser?.token, 1, 500, name)
      .then(response => {
        const group = sortGroups(response.data.data.groups.docs);
        const ambassadors = sortGroups(response.data.data.ambassadors.docs);
        const groupAmbassador: IGroupAmssador = {
          groups: group,
          ambassador: ambassadors
        };
        dispatch(setData(groupAmbassador));
        dispatch(setLoadingGroupAmbassador(false));
      })
      .catch(error => {
        dispatch(setLoadingGroupAmbassador(false));
      });
  };

  const regextValidationTwitter = (content: string) => {
    const regex = new RegExp(no_characters);
    return regex.test(content);
  };

  const regextValidationLinkedin = (content: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(content);
  };
  const regextValidationTiktok = (content: string) => {
    const regex = new RegExp(no_mentions);
    return regex.test(content);
  };

  const onSubmit = async (suggestedPostData: any) => {
    setLoader(true);
    suggestedPostData.name = suggestedPostData.name.trim().toLowerCase();
    if (!suggestedPostData?.contentTwitter && suggestedPost?.includesTwitter) {
      suggestedPostData.contentTwitter =
        suggestedPost?.contentPerSocialNetwork?.twitter.content;
    }
    if (
      !suggestedPostData?.contentInstagram &&
      suggestedPost?.includesInstagram
    ) {
      suggestedPostData.contentInstagram =
        suggestedPost?.contentPerSocialNetwork?.instagram?.content;
    }
    if (
      !suggestedPostData?.contentLinkedin &&
      suggestedPost?.includesLinkedin
    ) {
      suggestedPostData.contentLinkedin =
        suggestedPost?.contentPerSocialNetwork?.linkedin?.content;
    }
    if (!suggestedPostData?.contentTiktok && suggestedPost?.includesTiktok) {
      suggestedPostData.contentTiktok =
        suggestedPost?.contentPerSocialNetwork?.tiktok?.content;
    }
    if (
      !suggestedPostData?.contentWhatsapp &&
      suggestedPost?.includesWhatsapp
    ) {
      suggestedPostData.contentWhatsapp =
        suggestedPost?.contentPerSocialNetwork?.whatsapp?.content;
    }

    if (image.length === 0) {
      setBackendError(t('campaigns_page:image_required'));
      setLoader(false);
      return;
    }

    if (ambassadors.length == 0 && group.length == 0) {
      setBackendError(t('error_info_ambassador'));
      setLoader(false);
      return;
    }

    const pointsValue: any = {
      facebook: { ...suggestedPostData?.facebook },
      twitter: { ...suggestedPostData?.twitter },
      linkedin: { ...suggestedPostData?.linkedin },
      instagram: { ...suggestedPostData?.instagram },
      tiktok: { ...suggestedPostData?.tiktok },
      whatsapp: { ...suggestedPostData?.whatsapp }
    };
    for (const network in pointsValue) {
      const isEmptyPoint = Object.values(pointsValue[network]).some(
        point => point === null || point === ''
      );
      if (isEmptyPoint) {
        setBackendError(t('error_sn_points'));
        setLoader(false);
        return;
      }
    }

    if (suggestedPostData.includesTwitter) {
      const validationContent = regextValidationTwitter(
        suggestedPostData.contentTwitter
      );
      const contentTwitterLength = suggestedPostData.contentTwitter
        ? TwitterText.getTweetLength(suggestedPostData.contentTwitter)
        : 0;
      if (!suggestedPostData.contentTwitter) {
        setBackendError(t('error_text_twitter'));
        setLoader(false);
        return;
      } else if (!validationContent) {
        setBackendError(t('characters'));
        setLoader(false);
        return;
      } else if (contentTwitterLength > contentLimits.twitter) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          twitter: true
        });
        setLoader(false);
        return;
      }
    }

    if (suggestedPostData.includesTiktok) {
      const validationContent = regextValidationTiktok(
        suggestedPostData.contentTiktok
      );
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentTiktok || '').match(urlPattern) || []
      ).length;
      const contentTiktokLength = suggestedPostData.contentTiktok
        ? suggestedPostData.contentTiktok
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (imageTiktok.length == 0) {
        setBackendError(t('error_info_tiktok'));
        setLoader(false);
        return;
      } else if (!validationContent) {
        setBackendError(t('pattern_tiktok'));
        setLoader(false);
        return;
      } else if (contentTiktokLength > contentLimits.tiktok) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          tiktok: true
        });
        setLoader(false);
        return;
      }
    }

    if (suggestedPostData.includesLinkedin) {
      const validationContent = regextValidationLinkedin(
        suggestedPostData.contentLinkedin
      );
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentLinkedin || '').match(urlPattern) || []
      ).length;
      const contentLinkedinLength = suggestedPostData.contentLinkedin
        ? suggestedPostData.contentLinkedin
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;

      if (
        (type.linkedin === 'share' || type.linkedin === 'text') &&
        !suggestedPostData.contentLinkedin
      ) {
        setBackendError(t('error_text_linkedin'));
        setLoader(false);
        return;
      } else if (!validationContent) {
        setBackendError(t('pattern'));
        setLoader(false);
        return;
      } else if (contentLinkedinLength > contentLimits.linkedin) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          linkedin: true
        });
        setLoader(false);
        return;
      }
    }

    if (
      suggestedPostData.includesFacebook &&
      !facebookURLs(suggestedPostData.contentFacebook)
    ) {
      setBackendError(t('error_url'));
      setLoader(false);
      return;
    }

    if (suggestedPostData.includesInstagram) {
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentInstagram || '').match(urlPattern) || []
      ).length;
      const contentInstagramLength = suggestedPostData.contentInstagram
        ? suggestedPostData.contentInstagram
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (imageInstagram.length == 0) {
        setBackendError(t('error_info_instagram'));
        setLoader(false);
        return;
      } else if (contentInstagramLength > contentLimits.instagram) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          instagram: true
        });
        setLoader(false);
        return;
      }
    }

    if (
      type.twitter !== 'text' &&
      suggestedPostData.includesTwitter &&
      imageTwitter.length === 0
    ) {
      setBackendError(t('error_info_twitter'));
      setLoader(false);
      return;
    }

    if (
      type.linkedin !== 'text' &&
      suggestedPostData.includesLinkedin &&
      imageLinkedin.length === 0
    ) {
      setBackendError(t('error_info_linkedin'));
      setLoader(false);
      return;
    }

    if (
      type.tiktok === 'video' &&
      suggestedPostData.includesTiktok &&
      imageTiktok.length === 0
    ) {
      setBackendError(t('error_info_tiktok'));
      setLoader(false);
      return;
    }

    if (suggestedPostData.includesWhatsapp) {
      const urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
      const countPattern = (
        (suggestedPostData.contentWhatsapp || '').match(urlPattern) || []
      ).length;
      const contentWhatsappLength = suggestedPostData.contentWhatsapp
        ? suggestedPostData.contentWhatsapp
            .replace(/(?:https?|ftp):\/\/\S+/g, ' ')
            .trim().length + countPattern
        : 0;
      if (type.whatsapp !== 'text' && imageWhatsapp.length === 0) {
        setBackendError(t('error_info_whatsapp'));
        setLoader(false);
        return;
      } else if (contentWhatsappLength > contentLimits.whatsapp) {
        setBackendError(t('error_limit'));
        setInvalidLimit({
          ...invalidLimit,
          whatsapp: true
        });
        setLoader(false);
        return;
      }
    }

    suggestedPostData.startDate = new Date(
      `${startDate.getFullYear()}/${
        startDate.getMonth() + 1
      }/${startDate.getDate()} ${hourt}:${minutes}`
    ).toString();
    suggestedPostData.finishDate = new Date(
      `${finishDate.getFullYear()}/${
        finishDate.getMonth() + 1
      }/${finishDate.getDate()} 22:59:59`
    ).toString();
    suggestedPostData.companyId = currentUser.user.companyId;
    suggestedPostData.ambassadorsIds = ambassadors;
    suggestedPostData.groupsIds = group;

    if (editImage.photo) {
      const namePhoto = generateRandAlphaNumStr();
      const uploadedPhoto = await awsUploadFileHelper(
        currentUser?.token,
        image,
        true,
        'CAMPAIGN',
        namePhoto,
        t,
        setBackendError
      );
      if (uploadedPhoto) {
        suggestedPostData.photo = getUrlS3('campaigns', namePhoto);
      } else {
        setBackendError(t('validations:file_error'));
        setLoader(false);
        return;
      }
    }

    let instagram;
    if (suggestedPostData.includesInstagram) {
      if (editImage.instagram) {
        if (type.instagram === 'image') {
          const nameInstagram = setFileNameHelper(imageInstagram[0], false);
          const uploadedInstagramPhoto = await awsUploadFileHelper(
            currentUser?.token,
            imageInstagram[0],
            false,
            'CAMPAIGN',
            nameInstagram,
            t,
            setBackendError
          );
          if (uploadedInstagramPhoto) {
            instagram = {
              type: type['instagram'],
              url: [getUrlS3('campaigns', nameInstagram)]
            };
          } else {
            setBackendError(t('validations:file_error'));
            setLoader(false);
            return;
          }
        }
        if (type.instagram === 'video') {
          setUploadedInstagram(false);
          const nameInstagram = setFileNameHelper(imageInstagram, false);
          const uploadedInstagram = await awsUploadVideoHelper(
            currentUser?.token,
            imageInstagram,
            'CAMPAIGN',
            nameInstagram,
            t,
            setBackendError
          );
          if (uploadedInstagram) {
            instagram = {
              type: type['instagram'],
              url: getUrlS3('campaigns', nameInstagram)
            };
            setUploadedInstagram(true);
          } else {
            setBackendError(t('validations:file_error'));
            setLoader(false);
            return;
          }
        }
      } else {
        suggestedPost?.contentPerSocialNetwork?.instagram?.type === 'image'
          ? (instagram =
              suggestedPost?.contentPerSocialNetwork?.instagram?.picturesUrls)
          : (instagram =
              suggestedPost?.contentPerSocialNetwork?.instagram?.videoUrl);

        instagram = {
          type: suggestedPost?.contentPerSocialNetwork?.instagram?.type,
          url: instagram
        };
      }
    }

    let whatsapp;
    if (suggestedPostData.includesWhatsapp) {
      if (editImage.whatsapp) {
        if (type.whatsapp === 'image') {
          const nameWhatsapp = setFileNameHelper(imageWhatsapp[0], false);
          const uploadedWhatsappPhoto = await awsUploadFileHelper(
            currentUser?.token,
            imageWhatsapp[0],
            false,
            'CAMPAIGN',
            nameWhatsapp,
            t,
            setBackendError
          );
          if (uploadedWhatsappPhoto) {
            whatsapp = {
              type: type['whatsapp'],
              url: [getUrlS3('campaigns', nameWhatsapp)]
            };
          } else {
            setBackendError(t('validations:file_error'));
            setLoader(false);
            return;
          }
        }
        if (type.whatsapp === 'video') {
          setUploadedWhatsapp(false);
          const nameWhatsapp = setFileNameHelper(imageWhatsapp, false);
          const uploadedWhatsapp = await awsUploadVideoHelper(
            currentUser?.token,
            imageWhatsapp,
            'CAMPAIGN',
            nameWhatsapp,
            t,
            setBackendError
          );
          if (uploadedWhatsapp) {
            whatsapp = {
              type: type['whatsapp'],
              url: getUrlS3('campaigns', nameWhatsapp)
            };
            setUploadedWhatsapp(true);
          } else {
            setBackendError(t('validations:file_error'));
            setLoader(false);
            return;
          }
        }
      } else {
        suggestedPost?.contentPerSocialNetwork?.whatsapp?.type === 'image'
          ? (whatsapp =
              suggestedPost?.contentPerSocialNetwork?.whatsapp?.picturesUrls)
          : (whatsapp =
              suggestedPost?.contentPerSocialNetwork?.whatsapp?.videoUrl);

        whatsapp = {
          type: suggestedPost?.contentPerSocialNetwork?.whatsapp?.type,
          url: whatsapp
        };
      }
    }

    let twitter: any;
    let tiktok: any;
    let linkedin: any;

    if (editImage.twitter) {
      if (type.twitter === 'image') {
        const twitterFile = [];
        if (imageTwitter) {
          setUploadedTwitter(false);
        }
        for (const file of imageTwitter) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            twitterFile.push({ name });
            continue;
          }
          const nameTwitter = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CAMPAIGN',
            nameTwitter,
            t,
            setBackendError
          );
          twitterFile.push({ name: nameTwitter });
        }
        twitter = {
          type: type['twitter'],
          url: twitterFile.map((file: any) => getUrlS3('campaigns', file?.name))
        };
      }
      if (type.twitter === 'video') {
        setUploadedTwitter(false);
        const nameTwitter = setFileNameHelper(imageTwitter, false);
        const uploadedTwitter = await awsUploadVideoHelper(
          currentUser?.token,
          imageTwitter,
          'CAMPAIGN',
          nameTwitter,
          t,
          setBackendError
        );
        if (uploadedTwitter) {
          twitter = {
            type: type['twitter'],
            url: getUrlS3('campaigns', nameTwitter)
          };
          setUploadedTwitter(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    } else {
      suggestedPost?.contentPerSocialNetwork?.twitter?.type === 'image'
        ? (twitter =
            suggestedPost?.contentPerSocialNetwork?.twitter?.picturesUrls)
        : (twitter = suggestedPost?.contentPerSocialNetwork?.twitter?.videoUrl);

      twitter = {
        type: type['twitter'],
        url: twitter
      };
    }

    if (editImage.tiktok) {
      if (type.tiktok === 'video') {
        setUploadedTiktok(false);
        const nameTiktok = setFileNameHelper(imageTiktok, false);
        const uploadedTiktok = await awsUploadVideoHelper(
          currentUser?.token,
          imageTiktok,
          'CAMPAIGN',
          nameTiktok,
          t,
          setBackendError
        );
        if (uploadedTiktok) {
          tiktok = {
            type: type['tiktok'],
            url: getUrlS3('campaigns', nameTiktok)
          };
          setUploadedTiktok(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    } else {
      tiktok = suggestedPost?.contentPerSocialNetwork?.tiktok?.videoUrl;
      tiktok = {
        type: type['tiktok'],
        url: tiktok
      };
    }

    if (editImage.linkedin) {
      if (type.linkedin === 'image') {
        const linkedinFile = [];
        if (imageLinkedin) {
          setUploadedLinkedin(false);
        }
        for (const file of imageLinkedin) {
          if (!file?.file?.type) {
            const name = file.url.split('/').reverse()[0];
            linkedinFile.push({ name });
            continue;
          }
          const nameLinkedin = setFileNameHelper(file, false);
          await awsUploadFileHelper(
            currentUser?.token,
            file,
            false,
            'CAMPAIGN',
            nameLinkedin,
            t,
            setBackendError
          );
          linkedinFile.push({ name: nameLinkedin });
        }
        linkedin = {
          type: type['linkedin'],
          url: linkedinFile.map((file: any) =>
            getUrlS3('campaigns', file?.name)
          )
        };
      }
      if (type.linkedin === 'video') {
        setUploadedLinkedin(false);
        const nameLinkedin = setFileNameHelper(imageLinkedin, false);
        const uploadedLinkedin = await awsUploadVideoHelper(
          currentUser?.token,
          imageLinkedin,
          'CAMPAIGN',
          nameLinkedin,
          t,
          setBackendError
        );
        if (uploadedLinkedin) {
          linkedin = {
            type: type['linkedin'],
            url: getUrlS3('campaigns', nameLinkedin)
          };
          setUploadedLinkedin(true);
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
      if (type.linkedin === 'pdf') {
        const nameLinkedin = setFileNameHelper(imageLinkedin[0], false);
        const uploadedLinkedin = await awsUploadFileHelper(
          currentUser?.token,
          imageLinkedin[0],
          false,
          'CAMPAIGN',
          nameLinkedin,
          t,
          setBackendError
        );
        if (uploadedLinkedin) {
          linkedin = {
            type: type['linkedin'],
            url: getUrlS3('campaigns', nameLinkedin)
          };
        } else {
          setBackendError(t('validations:file_error'));
          setLoader(false);
          return;
        }
      }
    } else {
      suggestedPost?.contentPerSocialNetwork?.linkedin?.type === 'image'
        ? (linkedin =
            suggestedPost?.contentPerSocialNetwork?.linkedin?.picturesUrls)
        : suggestedPost?.contentPerSocialNetwork?.linkedin?.type === 'video'
        ? (linkedin =
            suggestedPost?.contentPerSocialNetwork?.linkedin?.videoUrl)
        : (linkedin = suggestedPost?.contentPerSocialNetwork?.linkedin?.pdfUrl);

      linkedin = {
        type: type['linkedin'],
        url: linkedin
      };
    }
    if (suggestedPostData.includesTwitter) {
      if (!suggestedPostData.contentTwitter) {
        const oldContent =
          suggestedPost?.contentPerSocialNetwork.twitter.content;
        suggestedPostData.contentTwitter = oldContent;
      }
    }

    const { data, errors } = await createCampaign(
      currentUser?.token,
      suggestedPostData,
      twitter,
      linkedin,
      instagram,
      tiktok,
      whatsapp,
      type
    );

    if (data) {
      history.push('/admin/campaigns', t('edit_success'));
    }

    if (errors) {
      setBackendError(errors.data);
      setLoader(false);
    }
    setLoader(false);
  };
  return (
    <>
      {backendSuccess && (
        <SuccessAlert
          text={backendSuccess}
          allowClose={true}
          onClose={() => setBackendSuccess(null)}
        />
      )}

      {backendError && (
        <ErrorAlert
          text={backendError}
          allowClose={true}
          onClose={() => setBackendError(null)}
        />
      )}

      <EditSuggestedPostForm
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setValue={setValue}
        handleClick={handleClick}
        groupsAmbassador={groupsAmbassador}
        getData={getGroup}
        handleParticipation={handleParticipation}
        handleImage={handleImagen}
        suggestedPost={suggestedPost}
        handleTypeFile={handleTypeFile}
        control={control}
        type={type}
        setImageTwitter={setImageTwitter}
        setImageLinkedin={setImageLinkedin}
        setImageInstagram={setImageInstagram}
        setImageWhatsapp={setImageWhatsapp}
        setImageTiktok={setImageTiktok}
        loader={loader}
        setHourt={setHourt}
        setMinutes={setMinutes}
        sHour={hourt}
        sMinutes={minutes}
        setBackendError={setBackendError}
        contentLimits={contentLimits}
        invalidLimit={invalidLimit}
        uploadedTwitter={uploadedTwitter}
        uploadedLinkedin={uploadedLinkedin}
        uploadedInstagram={uploadedInstagram}
        uploadedWhatsapp={uploadedWhatsapp}
        uploadedTiktok={uploadedTiktok}
      />
    </>
  );
};

export default EditCampaignNewPage;
