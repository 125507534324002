import styled from 'styled-components';
import { Breakpoints } from 'styles/Constants';

import { ToogleBox } from './MobileLinks/StyledMobileLinks';

export const NavBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  position: relative;
  padding: 0 30px;
  width: 100%;
  z-index: 3;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 0 130px;
  }
`;

export const NavBarLogo = styled.div`
  padding: 20px 0;

  img {
    max-width: 160px;

    &.dark-logo {
      display: block;
    }
    &.light-logo {
      display: none;
    }

    @media (min-width: ${Breakpoints.medium}) {
      max-width: 200px;
    }
  }
`;

export const NavBarWarning = styled.div`
  flex-shrink: 0;
  max-width: 54%;
  position: relative;
  display: flex;
   
  align-items: center;
  justify-content: space-between;
  color: #5a4a2f;
  background-color: #f9efd6;
  padding: 0.5%;
  margin: 0 20px
  font-size: 1rem;
  text-align: left;
  width: 100%;
  border: none;

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid #b98b56;
    color: #b98b56;
    margin-right: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .title {
    font-size: 15px;
    font-weight: 500;    
  }

  .subtitle {
    font-size: 14px;
    font-weight: normal;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .renew-button {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
    border: 2px solid #5a4a2f;
    border-radius: 4px;
    background: transparent;
    color: #5a4a2f;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .close-button {
    font-size: 1.5rem;
    background: transparent;
    border: none;
    color: #5a4a2f;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${Breakpoints.medium}) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    .actions {
      margin-top: 1rem;
      align-self: flex-end;
    }
  }
`;

export const NavBarLinks = styled.div`
  padding-right: 35px;
  align-items: center;
  display: flex;

  @media (min-width: ${Breakpoints.medium}) {
    padding: 0;
  }

  span {
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    display: none;

    @media (min-width: ${Breakpoints.medium}) {
      display: block;
    }
  }

  img.user-photo {
    border-radius: 100%;
    height: 35px;
    width: 35px;

    @media (min-width: ${Breakpoints.medium}) {
      margin-right: 10px;
    }
  }

  div.desktop-links {
    @media (max-width: ${Breakpoints.medium}) {
      display: none;
    }
  }
`;

export const NavBarWrapper = styled.div`
  transition: all 0.5s ease-out 0s;
  background: #fff;
  position: fixed;
  z-index: 999;
  width: 100%;
  right: 0;
  left: 0;
  top: 0;

  &.with-transparency.transparent {
    @media (max-width: ${Breakpoints.medium}) {
      background-color: transparent;
      background: transparent;

      ${NavBarHeader} {
        background: transparent;
      }

      img.dark-logo {
        display: none;
      }

      img.light-logo {
        display: block;
      }

      img.user-photo {
        display: none;
      }

      ${ToogleBox} {
        svg {
          fill: #fff;
        }

        &.menu-open {
          ul.mobile-navigation {
            background: #fff;
          }
        }
      }
    }
  }

  ul.desktop-navigation {
    @media (max-width: ${Breakpoints.medium}) {
      display: none;
    }
  }
`;
