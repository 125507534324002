import React from 'react';

export interface PostActions {
  onDeletePost: (id: number) => void;
  onAcceptPost: (id: number, status: string) => void;
  onProgramPost: (id: number, status: string) => void;
  onPublishPost: (id: number, status: string) => void;
  onErrorPost: (text: string) => void;
  campaignStatus?: string;
}

export const PostActionsContext = React.createContext<PostActions>({
  onDeletePost: () => {},
  onAcceptPost: () => {},
  onProgramPost: () => {},
  onPublishPost: () => {},
  onErrorPost: () => {}
});

export const usePostActions = () => React.useContext(PostActionsContext);
