import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { FilterCampaigns, StyleTable } from '../Styled';
import Input from 'components/inputs/Input';
import SuggestedPostCard from '../SuggestedPostCard';

interface AdminSuggestedPostsProps {
  suggestedPosts: any;
  setLoading: any;
  changeSuggestedPost: any;
  setChangeSuggestedPost: any;
  isAdmin?: any;
}

const AdminSuggestedPosts: React.FC<AdminSuggestedPostsProps> = props => {
  const {
    suggestedPosts,
    setLoading,
    changeSuggestedPost,
    setChangeSuggestedPost,
    isAdmin
  } = props;
  const { t } = useTranslation(['campaign_card', 'suggested_post']);
  const statusOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: t('suggested_post:pending'), value: 'pending' },
    { label: t('suggested_post:viewed'), value: 'viewed' }
  ];
  const socialOptions = [
    { label: t('campaign_card:all'), value: 'ALL' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Twitter', value: 'twitter' },
    { label: 'Linkedin', value: 'linkedin' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Whatsapp', value: 'whatsapp' }
  ];
  const [filterText, setFilterText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [selectedSocial, setSelectedSocial] = useState(socialOptions[0]);
  const [filterOption, setfilterOption] = useState(suggestedPosts);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const getFilterSocial = (filter: any, value: string) => {
    return filter.filter(
      (suggestedPost: any) => suggestedPost.contentPerSocialNetwork[value]
    );
  };

  const getFilterStatus = (filter: any, value: string) => {
    return filter.filter(
      (suggestedPost: any) => suggestedPost.status === value
    );
  };

  const selectStatusOption = (event: any) => {
    let filter = suggestedPosts;
    const filterStatus = event.value.toLowerCase();

    if (filterStatus !== 'all') {
      filter = getFilterStatus(filter, filterStatus);
    }
    if (selectedSocial.value.toLowerCase() !== 'all') {
      filter = getFilterSocial(filter, selectedSocial.value.toLowerCase());
    }
    setfilterOption(filter);
    setSelectedStatus(event);
  };

  const selectSocialOption = (event: any) => {
    let filter = suggestedPosts;
    const filterSocial = event.value.toLowerCase();

    if (filterSocial !== 'all') {
      filter = getFilterSocial(filter, filterSocial);
    }
    if (selectedStatus.value.toLowerCase() !== 'all') {
      filter = getFilterStatus(filter, selectedStatus.value.toLowerCase());
    }
    setfilterOption(filter);
    setSelectedSocial(event);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const newSuggestedPosts = suggestedPosts.filter((info: any) => {
    const data = info as any;
    const ambassadorName = data.ambassador.fullName.toLowerCase();
    const content = data?.name?.toLowerCase();
    const result =
      ambassadorName.indexOf(filterText.toLowerCase()) != -1 ||
      content.indexOf(filterText.toLowerCase()) != -1;
    return result;
  });

  const currentSuggestedPosts = filterText ? newSuggestedPosts : filterOption;

  return (
    <>
      <>
        <StyleTable>
          <FilterCampaigns>
            <Input
              type="search"
              placeholder={t('suggested_post:suggested_post_filter')}
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
              }}
            />
            <span>{t('campaign_card:filter')}</span>
            <DropdownSelect
              options={statusOptions}
              value={selectedStatus}
              onSelect={selectStatusOption}
            />
            <DropdownSelect
              options={socialOptions}
              value={selectedSocial}
              onSelect={selectSocialOption}
            />
          </FilterCampaigns>
          <TableContainer component={Paper} className="table_container">
            <Table
              sx={{ minWidth: 1000 }}
              size="medium"
              aria-label="a dense table"
              className="table"
            >
              <TableBody>
                {(rowsPerPage > 0
                  ? currentSuggestedPosts.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : currentSuggestedPosts
                ).map((suggestedPost: any, index: any) => {
                  return (
                    <SuggestedPostCard
                      key={index}
                      id={suggestedPost.id}
                      name={suggestedPost.name}
                      description={suggestedPost.description}
                      status={suggestedPost.status}
                      date={suggestedPost.createdAt}
                      ambassadorFullName={suggestedPost.ambassador.fullName}
                      setLoading={setLoading}
                      changeSuggestedPost={changeSuggestedPost}
                      setChangeSuggestedPost={setChangeSuggestedPost}
                      isAdmin={isAdmin}
                      includesFacebook={suggestedPost.includesFacebook}
                      includesTwitter={suggestedPost.includesTwitter}
                      includesLinkedin={suggestedPost.includesLinkedin}
                      includesInstagram={suggestedPost.includesInstagram}
                      includesWhatsapp={suggestedPost.includesWhatsapp}
                      includesTiktok={suggestedPost.includesTiktok}
                      points={suggestedPost.points}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100, 500]}
            component="div"
            count={currentSuggestedPosts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyleTable>
      </>
    </>
  );
};

export default AdminSuggestedPosts;
